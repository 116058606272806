<template>
    
        <!-- Lista de seguimiento -->
        <div class="section-heading padding mt-4">
            <h2 class="title">Lista de seguimiento</h2>
            <a href="#" class="link">Ver todo</a>
        </div>
        <!-- carrusel  -->
        <div class="carousel-multiple splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <!-- ítem -->
                    <li class="splide__slide">
                        <div class="card coinbox">
                            <div class="card-body pb-0">
                                <a href="#" class="fixed-button" data-bs-toggle="modal" data-bs-target="#actionSheetWatchlist">
                                    <ion-icon name="ellipsis-vertical"></ion-icon>
                                </a>
                                <h4>BTC/USD</h4>
                                <div class="text">$38,509.44</div>
                                <div class="change">
                                    <span class="badge badge-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                        6.78%
                                    </span>
                                </div>
                            </div>
                            <div class="chart chart-sparkline-success-1"></div>
                        </div>
                    </li>
                    <!-- * ítem -->
                    <!-- ítem -->
                    <li class="splide__slide">
                        <div class="card coinbox">
                            <div class="card-body pb-0">
                                <a href="#" class="fixed-button" data-bs-toggle="modal"
                                    data-bs-target="#actionSheetWatchlist">
                                    <ion-icon name="ellipsis-vertical"></ion-icon>
                                </a>
                                <h4>ETH/USD</h4>
                                <div class="text">$1,462.61</div>
                                <div class="change">
                                    <span class="badge badge-danger">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                        2.54%
                                    </span>
                                </div>
                            </div>
                            <div class="chart chart-sparkline-danger-1"></div>
                        </div>
                    </li>
                    <!-- * ítem -->
                    <!-- ítem -->
                    <li class="splide__slide">
                        <div class="card coinbox">
                            <div class="card-body pb-0">
                                <a href="#" class="fixed-button" data-bs-toggle="modal"
                                    data-bs-target="#actionSheetWatchlist">
                                    <ion-icon name="ellipsis-vertical"></ion-icon>
                                </a>
                                <h4>USDT/USD</h4>
                                <div class="text">$0.99995</div>
                                <div class="change">
                                    <span class="badge badge-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                        0.05%
                                    </span>
                                </div>
                            </div>
                            <div class="chart chart-sparkline-success-2"></div>
                        </div>
                    </li>
                    <!-- * ítem -->
                    <!-- ítem -->
                    <li class="splide__slide">
                        <div class="card coinbox">
                            <div class="card-body pb-0">
                                <a href="#" class="fixed-button" data-bs-toggle="modal"
                                    data-bs-target="#actionSheetWatchlist">
                                    <ion-icon name="ellipsis-vertical"></ion-icon>
                                </a>
                                <h4>DOGE/USD</h4>
                                <div class="text">$0.198410</div>
                                <div class="change">
                                    <span class="badge badge-success">
                                        <ion-icon name="arrow-up-outline"></ion-icon>
                                        1.73%
                                    </span>
                                </div>
                            </div>
                            <div class="chart chart-sparkline-success-1"></div>
                        </div>
                    </li>
                    <!-- * ítem -->
                    <!-- ítem -->
                    <li class="splide__slide">
                        <div class="card coinbox">
                            <div class="card-body pb-0">
                                <a href="#" class="fixed-button" data-bs-toggle="modal"
                                    data-bs-target="#actionSheetWatchlist">
                                    <ion-icon name="ellipsis-vertical"></ion-icon>
                                </a>
                                <h4>ADA/USD</h4>
                                <div class="text">$1.271472</div>
                                <div class="change">
                                    <span class="badge badge-danger">
                                        <ion-icon name="arrow-down-outline"></ion-icon>
                                        0.69%
                                    </span>
                                </div>
                            </div>
                            <div class="chart chart-sparkline-danger-2"></div>
                        </div>
                    </li>
                    <!-- * ítem -->
                </ul>
            </div>
        </div>
        <!-- * carrusel  -->
        <!-- Lista de seguimiento -->
        <!-- Hoja de acción de la lista de seguimiento -->
        <div class="modal fade action-sheet" id="actionSheetWatchlist" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">BTC/USD</h5>
                        <h3 class="text-center mb-05 fontsize-headingLarge text-success">$38,509.44</h3>
                        <div class="text-muted text-center mb-1 fontsize-caption">Añadido hace 28 días</div>
                    </div>

                    <div class="modal-body">
                        <ul class="action-button-list">
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Comprar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Vender</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Eliminar de la lista</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Ocultar de la lista</span>
                                </a>
                            </li>
                            <li class="action-divider"></li>
                            <li>
                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                    <span>Cerrar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción de la lista de seguimiento -->
</template>

<script>
import ApexCharts from 'apexcharts'
export default {
    name: 'HomeView',
    created() {
        
    },
    mounted() {
        this.runScripts()
        
        setTimeout(() => {
        }, 1500)
    },
    methods: {
      runScripts() {
        var sparklineAreaExampleSuccess1 = {
            series: [{
                data: [555, 1222, 777, 888, 555, 888, 999, 1222]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 70,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };
        var sparklineAreaExampleSuccess2 = {
            series: [{
                data: [55, 66, 55, 77, 66, 180, 290, 333]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 70,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#1DCC70'],
            tooltip: {
                enabled: false
            }
        };
        var sparklineAreaExampleDanger1 = {
            series: [{
                data: [2222, 1666, 1444, 1777, 1333, 1111, 777, 666]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 70,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#FF396F'],
            tooltip: {
                enabled: false
            }
        };
        var sparklineAreaExampleDanger2 = {
            series: [{
                data: [1200, 1444, 2900, 505, 531, 1900, 555, 75]
            }],
            chart: {
                type: 'area',
                width: '100%',
                height: 70,
                sparkline: {
                    enabled: true
                }
            },
            stroke: {
                width: 2,
            },
            colors: ['#FF396F'],
            tooltip: {
                enabled: false
            }
        };

        // document.addEventListener('DOMContentLoaded', function () {
        // });
        document.querySelectorAll('.chart-sparkline-success-1').forEach(chart => new ApexCharts(chart, sparklineAreaExampleSuccess1).render());
        document.querySelectorAll('.chart-sparkline-success-2').forEach(chart => new ApexCharts(chart, sparklineAreaExampleSuccess2).render());
        document.querySelectorAll('.chart-sparkline-danger-1').forEach(chart => new ApexCharts(chart, sparklineAreaExampleDanger1).render());
        document.querySelectorAll('.chart-sparkline-danger-2').forEach(chart => new ApexCharts(chart, sparklineAreaExampleDanger2).render());

        // Multiple Carousel
        document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
            perPage: 4,
            rewind: true,
            type: "loop",
            gap: 16,
            padding: 16,
            arrows: false,
            pagination: false,
            breakpoints: {
                768: {
                    perPage: 2
                },
                991: {
                    perPage: 3
                }
            }
        }).mount());
      },
    },
}
</script>