<template>
    <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
        <template v-for="(asset_, i) in assets">
            <li v-if="i < (maxl)">
                <portfolio-list-item :record="asset_" />
            </li>
        </template>
    </ul>
</template>

<script>
export default {
    props: {
        assets: {
            type: Array,
            default: [],
        },
        maxl: {
            type: Number,
            default: 5,
        }
    },
    methods: {
    }
}
</script>