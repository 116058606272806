<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Precargador</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Spinner de borde</div>
            <div class="card">
                <ul class="listview flush transparent simple-listview">
                    <li>
                        <code>.text-primary</code>
                        <div class="spinner-border text-primary" role="status"></div>
                    </li>
                    <li>
                        <code>.text-secondary</code>
                        <div class="spinner-border text-secondary" role="status"></div>
                    </li>
                    <li>
                        <code>.text-success</code>
                        <div class="spinner-border text-success" role="status"></div>
                    </li>
                    <li>
                        <code>.text-danger</code>
                        <div class="spinner-border text-danger" role="status"></div>
                    </li>
                    <li>
                        <code>.text-warning</code>
                        <div class="spinner-border text-warning" role="status"></div>
                    </li>
                    <li>
                        <code>.text-info</code>
                        <div class="spinner-border text-info" role="status"></div>
                    </li>
                    <li>
                        <code>.text-light</code>
                        <div class="spinner-border text-light" role="status"></div>
                    </li>
                    <li>
                        <code>.text-dark</code>
                        <div class="spinner-border text-dark" role="status"></div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Spinner creciente</div>
            <div class="card">
                <ul class="listview flush transparent simple-listview">
                    <li>
                        <code>.text-primary</code>
                        <div class="spinner-grow text-primary" role="status"></div>
                    </li>
                    <li>
                        <code>.text-secondary</code>
                        <div class="spinner-grow text-secondary" role="status"></div>
                    </li>
                    <li>
                        <code>.text-success</code>
                        <div class="spinner-grow text-success" role="status"></div>
                    </li>
                    <li>
                        <code>.text-danger</code>
                        <div class="spinner-grow text-danger" role="status"></div>
                    </li>
                    <li>
                        <code>.text-warning</code>
                        <div class="spinner-grow text-warning" role="status"></div>
                    </li>
                    <li>
                        <code>.text-info</code>
                        <div class="spinner-grow text-info" role="status"></div>
                    </li>
                    <li>
                        <code>.text-light</code>
                        <div class="spinner-grow text-light" role="status"></div>
                    </li>
                    <li>
                        <code>.text-dark</code>
                        <div class="spinner-grow text-dark" role="status"></div>
                    </li>
                </ul>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Spinners de botones</div>
            <div class="card">
                <div class="card-body">
                    <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </button>

                    <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-border spinner-border-sm me-05" role="status" aria-hidden="true"></span>
                        Cargando...
                    </button>

                    <br><br>

                    <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    </button>

                    <button class="btn btn-primary" type="button" disabled>
                        <span class="spinner-grow spinner-grow-sm me-05" role="status" aria-hidden="true"></span>
                        Cargando...
                    </button>
                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->
</template>