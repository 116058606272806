<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Carrusel</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">


        <div class="section full mt-2 mb-3">
            <div class="section-title">Carrusel Completo</div>


            <!-- carrusel completo -->
            <div class="carousel-full splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <div class="card rounded-0">
                                <div class="card-body">
                                    <h5 class="card-title">Deslízame</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card rounded-0">
                                <div class="card-body">
                                    <h5 class="card-title">Otro Título de Tarjeta</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card rounded-0">
                                <div class="card-body">
                                    <h5 class="card-title">Otro más</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- * carrusel completo -->

        </div>


        <div class="section full mb-3">
            <div class="section-title">Carrusel Simple</div>

            <!-- carrusel simple -->
            <div class="carousel-single splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <div class="card">
                                <img src="@/assets/img/sample/photo/wide2.jpg" class="card-img-top" alt="image">
                                <div class="card-body">
                                    <h5 class="card-title">Elemento de Carrusel</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card">
                                <img src="@/assets/img/sample/photo/wide3.jpg" class="card-img-top" alt="image">
                                <div class="card-body">
                                    <h5 class="card-title">Título del Elemento</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card">
                                <img src="@/assets/img/sample/photo/wide4.jpg" class="card-img-top" alt="image">
                                <div class="card-body">
                                    <h5 class="card-title">Otro Título de Elemento</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta y
                                        componer la mayor parte del contenido de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- * carrusel simple -->

        </div>

        <div class="section full mb-3">
            <div class="section-title">Carrusel Múltiple</div>

            <!-- carrusel múltiple -->
            <div class="carousel-multiple splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Título 1</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Título 2</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Título 3</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card">
                                <div class="card-body">
                                    <h5 class="card-title">Título 4</h5>
                                    <p class="card-text">
                                        Un poco de texto de ejemplo rápido para construir sobre el título de la tarjeta.
                                    </p>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
            <!-- * carrusel múltiple -->

        </div>

        <div class="section full mb-3">
            <div class="section-title">Carrusel Pequeño</div>

            <!-- carrusel pequeño -->
            <div class="carousel-small splide">
                <div class="splide__track">
                    <ul class="splide__list">
                        <li class="splide__slide">
                            <img src="@/assets/img/sample/photo/1.jpg" alt="alt" class="imaged w-100">
                        </li>
                        <li class="splide__slide">
                            <img src="@/assets/img/sample/photo/3.jpg" alt="alt" class="imaged w-100">
                        </li>
                        <li class="splide__slide">
                            <img src="@/assets/img/sample/photo/4.jpg" alt="alt" class="imaged w-100">
                        </li>
                        <li class="splide__slide">
                            <img src="@/assets/img/sample/photo/5.jpg" alt="alt" class="imaged w-100">
                        </li>
                        <li class="splide__slide">
                            <img src="@/assets/img/sample/photo/6.jpg" alt="alt" class="imaged w-100">
                        </li>
                    </ul>
                </div>
            </div>
            <!-- * carrusel pequeño -->

        </div>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>

<script>
export default {
    mounted() {
        this.runScripts()
    },
    methods: {
        runScripts() {
            
            //-----------------------------------------------------------------------
            // Carousel
            // Splide Carousel
            document.addEventListener('DOMContentLoaded', function () {

                // Full Carousel
                document.querySelectorAll('.carousel-full').forEach(carousel => new Splide(carousel, {
                    perPage: 1,
                    rewind: true,
                    type: "loop",
                    gap: 0,
                    arrows: false,
                    pagination: false,
                }).mount());

                // Single Carousel
                document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
                    perPage: 3,
                    rewind: true,
                    type: "loop",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: false,
                    breakpoints: {
                        768: {
                            perPage: 1
                        },
                        991: {
                            perPage: 2
                        }
                    }
                }).mount());

                // Multiple Carousel
                document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
                    perPage: 4,
                    rewind: true,
                    type: "loop",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: false,
                    breakpoints: {
                        768: {
                            perPage: 2
                        },
                        991: {
                            perPage: 3
                        }
                    }
                }).mount());

                // Small Carousel
                document.querySelectorAll('.carousel-small').forEach(carousel => new Splide(carousel, {
                    perPage: 9,
                    rewind: false,
                    type: "loop",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: false,
                    breakpoints: {
                        768: {
                            perPage: 4
                        },
                        991: {
                            perPage: 7
                        }
                    }
                }).mount());

                // Slider Carousel
                document.querySelectorAll('.carousel-slider').forEach(carousel => new Splide(carousel, {
                    perPage: 1,
                    rewind: false,
                    type: "loop",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: true
                }).mount());

                // Stories Carousel
                document.querySelectorAll('.story-block').forEach(carousel => new Splide(carousel, {
                    perPage: 16,
                    rewind: false,
                    type: "slide",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: false,
                    breakpoints: {
                        500: {
                            perPage: 4
                        },
                        768: {
                            perPage: 7
                        },
                        1200: {
                            perPage: 11
                        }
                    }
                }).mount());
            });
            //-----------------------------------------------------------------------

        }
    }
}
</script>