<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader bg-primary text-light">
        <div class="left">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged rounded w32">
                <span class="badge badge-danger">10</span>
            </a>
        </div>
        <div class="pageTitle">
            {{ $root.site.name.regular }}
        </div>
        <div class="right">
            <a href="app-notifications.html" class="headerButton">
                <ion-icon class="icon" name="notifications-outline"></ion-icon>
                <span class="badge badge-danger">8</span>
            </a>
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">
        <section-wallet />
        <section-portfolio :assets="$root.assets" maxl="5" />
        <!-- <section-watchlist /> -->
        <section-transactions />
        <section-promo />
        <!-- <section-news /> -->
        <br />
        <app-footer />
    </div>
    <!-- * App Capsule -->

    <app-menu-bottom />

    <!-- Barra lateral de la aplicación -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- cuadro de perfil -->
                    <div class="profileBox pt-2 pb-2">
                        <div class="image-wrapper">
                            <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36">
                        </div>
                        <div class="in">
                            <strong>Sebastian Doe</strong>
                            <div class="text-muted">4029209</div>
                        </div>
                        <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <!-- * cuadro de perfil -->
                    <!-- saldo -->
                    <div class="sidebar-balance">
                        <div class="listview-title">Saldo</div>
                        <div class="in">
                            <h1 class="amount">$ 2,562.50</h1>
                        </div>
                    </div>
                    <!-- * saldo -->

                    <!-- grupo de acción -->
                    <div class="action-group">
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="add-outline"></ion-icon>
                                </div>
                                Depósito
                            </div>
                        </a>
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-down-outline"></ion-icon>
                                </div>
                                Retiro
                            </div>
                        </a>
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                </div>
                                Enviar
                            </div>
                        </a>
                        <a href="app-cards.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                Mis tarjetas
                            </div>
                        </a>
                    </div>
                    <!-- * grupo de acción -->

                    <!-- menú -->
                    <div class="listview-title mt-1">Menú</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="index.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="pie-chart-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Visión general
                                    <span class="badge badge-primary">10</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-pages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Páginas
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-components.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Componentes
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-cards.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Mis tarjetas
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * menú -->

                    <!-- otros -->
                    <div class="listview-title mt-1">Otros</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="app-settings.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="settings-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Configuración
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="component-messages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="chatbubble-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Soporte
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="/logout" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Cerrar sesión
                                </div>
                            </a>
                        </li>


                    </ul>
                    <!-- * otros -->

                    <!-- enviar dinero -->
                    <div class="listview-title mt-1">Enviar dinero</div>
                    <ul class="listview image-listview flush transparent no-line">
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Artem Sazonov</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Sophie Asveld</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Kobus van de Vegte</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * enviar dinero -->

                </div>
            </div>
        </div>
    </div>
    <!-- * Barra lateral de la aplicación -->

</template>

<script>
export default {
    name: 'CryptoHomeView',
    created() {
    },
    mounted() {
        this.runCarousel()
    },
    methods: {
        runCarousel() {
            // Single Carousel
            document.querySelectorAll('.carousel-single').forEach(carousel => new Splide(carousel, {
                perPage: 3,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 1
                    },
                    991: {
                        perPage: 2
                    }
                }
            }).mount());
            // Multiple Carousel
            document.querySelectorAll('.carousel-multiple').forEach(carousel => new Splide(carousel, {
                perPage: 4,
                rewind: true,
                type: "loop",
                gap: 16,
                padding: 16,
                arrows: false,
                pagination: false,
                breakpoints: {
                    768: {
                        perPage: 2
                    },
                    991: {
                        perPage: 3
                    }
                }
            }).mount());
        }
    },
}
</script>