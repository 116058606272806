<template>
    

    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Información sobre herramientas</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-1 mb-2">
            <div class="section-title">Direcciones</div>
            <div class="card">
                <div class="card-body">
                    <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
                        title="Tooltip on top">
                        Superior
                    </button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="right"
                        title="Tooltip on right">
                        Derecha
                    </button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        title="Tooltip on bottom">
                        Inferior
                    </button>
                    <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="left"
                        title="Tooltip on left">
                        Izquierda
                    </button>
                </div>
            </div>
        </div>

        <div class="section mb-2">
            <div class="section-title">Información sobre herramientas HTML personalizado</div>
            <div class="card">
                <div class="card-body">
                    <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-html="true"
                        title="<em>Información sobre herramientas</em> <u>con</u> <b>HTML</b>">
                        Información sobre herramientas con HTML
                    </button>
                </div>
            </div>
        </div>

        <div class="section mb-2">
            <div class="section-title">Texto</div>
            <div class="card">
                <div class="card-body">
                    Lorem ipsum dolor sit amet
                    <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Información sobre herramientas en la parte superior">
                        ultrices
                    </a>
                    purus sed hendrerit. Praesent et auctor dolor. Ut sed ultrices justo.
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget finibus
                    fermentum,
                    velit metus maximus erat, nec sodales elit justo vitae sapien. Sed fermentum varius erat, et dictum
                    lorem. Cras pulvinar purus sed hendrerit. Praesent et auctor dolor. Ut sed ultrices justo.
                    Phasellus pulvinar lacus nec quam blandit, vel rhoncus risus scelerisque. Morbi diam ex, rhoncus sit
                    amet augue vitae.
                    <a href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Otra información sobre herramientas">Proin
                        blandit</a>
                    fringilla sem quis luctus. Phasellus ex lectus, mattis in bibendum et, semper id mi. Praesent erat
                    ante, posuere ut bibendum a, dignissim id
                    est. Morbi ac mi leo. Cras molestie tincidunt augue id eleifend. Donec vehicula dolor sit amet
                    ultrices vehicula. In tempus libero elementum, sagittis felis ut, pharetra dolor. Phasellus
                    vulputate nibh sapien, in semper sem scelerisque sed.

                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->
</template>

<script>
import {Tooltip} from 'bootstrap';
export default {
    mounted() {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl)
        })
    }
}
</script>