<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Pestañas</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->


    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-1">
            <div class="section-title">Con líneas</div>
            <div class="card">
                <div class="card-body pt-0">

                    <ul class="nav nav-tabs lined" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#overview2" role="tab">
                                Visión general
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#cards2" role="tab">
                                Tarjetas
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#settings2" role="tab">
                                Configuración
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content mt-2">
                        <div class="tab-pane fade show active" id="overview2" role="tabpanel">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate enim sed elit
                            consequat, sed ultricies ligula venenatis. In nec arcu eget neque sodales accumsan vel
                            et neque.
                        </div>
                        <div class="tab-pane fade" id="cards2" role="tabpanel">
                            Suspendisse maximus ligula eu ligula iaculis, eu bibendum odio dignissim. Pellentesque
                            elementum nisl elit, non feugiat risus luctus sit amet.
                        </div>
                        <div class="tab-pane fade" id="settings2" role="tabpanel">
                            Vestibulum sed facilisis diam, vel sodales leo. Aenean lacinia, nisi sit amet iaculis
                            maximus, nibh orci iaculis risus, vitae faucibus dui orci quis elit.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Con iconos</div>
            <div class="card">
                <div class="card-body">

                    <ul class="nav nav-tabs lined" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#example1b" role="tab">
                                <ion-icon name="wallet"></ion-icon>
                                Pestaña 1
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example2b" role="tab">
                                <ion-icon name="card"></ion-icon>
                                Pestaña 2
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example3b" role="tab">
                                <ion-icon name="settings"></ion-icon>
                                Pestaña 3
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Solo iconos</div>
            <div class="card">
                <div class="card-body">

                    <ul class="nav nav-tabs lined" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#example1b" role="tab">
                                <ion-icon name="wallet"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example2b" role="tab">
                                <ion-icon name="card"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example3b" role="tab">
                                <ion-icon name="settings"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example4b" role="tab">
                                <ion-icon name="cart"></ion-icon>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>



        <div class="section mt-3">
            <div class="section-title">Pestañas</div>
            <div class="card">
                <div class="card-body">
                    <ul class="nav nav-tabs capsuled" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#overview" role="tab">
                                Visión general
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#card" role="tab">
                                Tarjetas
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#settings" role="tab">
                                Configuración
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content mt-1">
                        <div class="tab-pane fade show active" id="overview" role="tabpanel">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vulputate enim sed elit
                            consequat, sed ultricies ligula venenatis. In nec arcu eget neque sodales accumsan vel
                            et neque.
                        </div>
                        <div class="tab-pane fade" id="card" role="tabpanel">
                            Suspendisse maximus ligula eu ligula iaculis, eu bibendum odio dignissim. Pellentesque
                            elementum nisl elit, non feugiat risus luctus sit amet.
                        </div>
                        <div class="tab-pane fade" id="settings" role="tabpanel">
                            Vestibulum sed facilisis diam, vel sodales leo. Aenean lacinia, nisi sit amet iaculis
                            maximus, nibh orci iaculis risus, vitae faucibus dui orci quis elit.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Con iconos</div>
            <div class="card">
                <div class="card-body">

                    <ul class="nav nav-tabs capsuled" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#example1b" role="tab">
                                <ion-icon name="wallet"></ion-icon>
                                Pestaña 1
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example2b" role="tab">
                                <ion-icon name="card"></ion-icon>
                                Pestaña 2
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example3b" role="tab">
                                <ion-icon name="settings"></ion-icon>
                                Pestaña 3
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Solo iconos</div>
            <div class="card">
                <div class="card-body">

                    <ul class="nav nav-tabs capsuled" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-bs-toggle="tab" href="#example1b" role="tab">
                                <ion-icon name="wallet"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example2b" role="tab">
                                <ion-icon name="card"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example3b" role="tab">
                                <ion-icon name="settings"></ion-icon>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-bs-toggle="tab" href="#example4b" role="tab">
                                <ion-icon name="cart"></ion-icon>
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </div>




    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>