<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Botones</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-1">
            <div class="section-title">Predeterminado</div>
            <div class="card">
                <div class="card-body pb-1">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-primary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-secondary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-success me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-danger me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-warning me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-info me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-light me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-dark me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-link me-1 mb-1">BOTÓN</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Con contorno</div>
            <div class="card">
                <div class="card-body pb-1">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-outline-primary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-secondary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-success me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-danger me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-warning me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-info me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-dark me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-light me-1 mb-1">BOTÓN</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Botones de texto</div>
            <div class="card">
                <div class="card-body pb-1">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-text-primary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-secondary me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-success me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-danger me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-warning me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-info me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-dark me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-light me-1 mb-1">BOTÓN</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Botón de icono</div>
            <div class="card">
                <div class="card-body">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-icon btn-primary me-1">
                        <ion-icon name="lock-closed-outline"></ion-icon>
                    </button>
                    <button type="button" class="btn btn-icon btn-outline-primary me-1">
                        <ion-icon name="newspaper-outline"></ion-icon>
                    </button>
                    <button type="button" class="btn btn-icon btn-success me-1">
                        <ion-icon name="pricetag-outline"></ion-icon>
                    </button>
                    <button type="button" class="btn btn-icon btn-warning me-1">
                        <ion-icon name="share-outline"></ion-icon>
                    </button>
                    <button type="button" class="btn btn-icon btn-info me-1">
                        <ion-icon name="add-outline"></ion-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Radio del botón</div>
            <div class="card">
                <div class="card-body">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-primary me-1">BOTÓN</button>
                    <button type="button" class="btn btn-primary rounded me-1">BOTÓN</button>
                    <button type="button" class="btn btn-primary square me-1">BOTÓN</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Sombreado</div>
            <div class="card">
                <div class="card-body">
                    <p>Añadir <code>.shadowed</code> para hacer que el botón se eleve</p>
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-primary shadowed  me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-primary shadowed me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-primary shadowed me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-primary rounded shadowed  me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-outline-primary rounded shadowed me-1 mb-1">BOTÓN</button>
                    <button type="button" class="btn btn-text-primary rounded shadowed me-1 mb-1">BOTÓN</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Tamaños</div>
            <div class="card">
                <div class="card-body">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-primary btn-sm me-1">PEQUEÑO</button>
                    <button type="button" class="btn btn-primary me-1">PREdeterminado</button>
                    <button type="button" class="btn btn-primary btn-lg me-1">GRANDE</button>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Bloqueado</div>
            <div class="card">
                <div class="card-body">

                    <button type="button" class="btn btn-primary btn-sm btn-block">BOTÓN PEQUEÑO</button>

                    <div class="mt-1"></div>

                    <button type="button" class="btn btn-primary btn-block">BOTÓN PREdeterminado</button>

                    <div class="mt-1"></div>

                    <button type="button" class="btn btn-primary btn-lg btn-block">BOTÓN GRANDE</button>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Con icono</div>
            <div class="card">
                <div class="card-body">
                    <!-- no olvides eliminar me-1 y mb-1 al copiar / pegar códigos -->
                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1">
                        <ion-icon name="add-outline"></ion-icon>
                        PEQUEÑO
                    </button>
                    <br>
                    <button type="button" class="btn btn-primary me-1 mb-1">
                        <ion-icon name="add-outline"></ion-icon>
                        PREdeterminado
                    </button>
                    <br>
                    <button type="button" class="btn btn-primary btn-lg me-1">
                        <ion-icon name="add-outline"></ion-icon>
                        GRANDE
                    </button>
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Casillas de verificación y botones de radio</div>
            <div class="card">
                <div class="card-body">

                    <div class="btn-group" role="group">
                        <input type="radio" class="btn-check" name="btnradioGroup1" id="btnradio1" checked>
                        <label class="btn btn-outline-primary" for="btnradio1">Uno</label>

                        <input type="radio" class="btn-check" name="btnradioGroup1" id="btnradio2">
                        <label class="btn btn-outline-primary" for="btnradio2">Dos</label>

                        <input type="radio" class="btn-check" name="btnradioGroup1" id="btnradio3">
                        <label class="btn btn-outline-primary" for="btnradio3">Tres</label>
                    </div>

                    <div class="mt-1"></div>

                    <div class="btn-group" role="group">
                        <input type="radio" class="btn-check" name="btnradioGroup2" id="btnradio1b" checked>
                        <label class="btn btn-outline-danger" for="btnradio1b">1</label>

                        <input type="radio" class="btn-check" name="btnradioGroup2" id="btnradio2b">
                        <label class="btn btn-outline-danger" for="btnradio2b">2</label>

                        <input type="radio" class="btn-check" name="btnradioGroup2" id="btnradio3b">
                        <label class="btn btn-outline-danger" for="btnradio3b">3</label>
                    </div>

                    <div class="mt-1"></div>

                    <div class="btn-group" role="group">
                        <input type="radio" class="btn-check" name="btnradioGroup3" id="btnradio1c" checked>
                        <label class="btn btn-outline-success" for="btnradio1c">$25</label>

                        <input type="radio" class="btn-check" name="btnradioGroup3" id="btnradio2c">
                        <label class="btn btn-outline-success" for="btnradio2c">$50</label>

                        <input type="radio" class="btn-check" name="btnradioGroup3" id="btnradio3c">
                        <label class="btn btn-outline-success" for="btnradio3c">$100</label>
                    </div>

                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>