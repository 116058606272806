<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Dialog</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Básico</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogBasic">
                            <div class="in">
                                <div>Básico</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogBlockButton">
                            <div class="in">
                                <div>Botones de Bloque</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogImaged">
                            <div class="in">
                                <div>con Imagen</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <!-- Dialog Basic -->
        <div class="modal fade dialogbox" id="DialogBasic" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviando $50 a John</h5>
                    </div>
                    <div class="modal-body">
                        ¿Estás seguro de eso?
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                            <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">ENVIAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Basic -->

        <!-- Dialog Block Button -->
        <div class="modal fade dialogbox" id="DialogBlockButton" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviando $50 a John</h5>
                    </div>
                    <div class="modal-body">
                        ¿Estás seguro de eso?
                    </div>
                    <div class="modal-footer">
                        <div class="btn-list">
                            <a href="#" class="btn btn-text-primary btn-block" data-bs-dismiss="modal">ENVIAR</a>
                            <a href="#" class="btn btn-text-secondary btn-block" data-bs-dismiss="modal">CANCELAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Block Button -->


        <!-- Dialog with Image -->
        <div class="modal fade dialogbox" id="DialogImaged" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="pt-3 text-center">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w48 rounded mb-1">
                    </div>
                    <div class="modal-header pt-2">
                        <h5 class="modal-title">Enviando $50 a John</h5>
                    </div>
                    <div class="modal-body">
                        ¿Estás seguro de eso?
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                            <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">ENVIAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog with Image -->

        <div class="section mt-2">
            <div class="section-title">Con Icono</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogIconedInfo">
                            <div class="in">
                                <div>Información</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogIconedSuccess">
                            <div class="in">
                                <div>Éxito</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogIconedDanger">
                            <div class="in">
                                <div>Peligro</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <!-- DialogIconedSuccess -->
        <div class="modal fade dialogbox" id="DialogIconedSuccess" data-bs-backdrop="static" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-icon text-success">
                        <ion-icon name="checkmark-circle"></ion-icon>
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">Éxito</h5>
                    </div>
                    <div class="modal-body">
                        Tu pago ha sido enviado.
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn" data-bs-dismiss="modal">CERRAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * DialogIconedSuccess -->

        <!-- DialogIconedDanger -->
        <div class="modal fade dialogbox" id="DialogIconedDanger" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-icon text-danger">
                        <ion-icon name="close-circle"></ion-icon>
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">Error</h5>
                    </div>
                    <div class="modal-body">
                        Hay algo malo.
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn" data-bs-dismiss="modal">CERRAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * DialogIconedDanger -->

        <!-- DialogIconedInfo -->
        <div class="modal fade dialogbox" id="DialogIconedInfo" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-icon">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">Tarjeta Caducada</h5>
                    </div>
                    <div class="modal-body">
                        Tu tarjeta ha caducado.
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn" data-bs-dismiss="modal">CERRAR</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * DialogIconedInfo -->


        <div class="section mt-2">
            <div class="section-title">Botones con Icono</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogIconedButton">
                            <div class="in">
                                <div>Botón de Bloque</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogIconedButtonInline">
                            <div class="in">
                                <div>Botón en Línea</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <!-- Dialog Iconed Block -->
        <div class="modal fade dialogbox" id="DialogIconedButton" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviando $50 a John</h5>
                    </div>
                    <div class="modal-body">
                        ¿Estás seguro de eso?
                    </div>
                    <div class="modal-footer">
                        <div class="btn-list">
                            <a href="#" class="btn btn-text-primary btn-block" data-bs-dismiss="modal">
                                <ion-icon name="checkmark-outline"></ion-icon>
                                ENVIAR
                            </a>
                            <a href="#" class="btn btn-text-danger btn-block" data-bs-dismiss="modal">
                                <ion-icon name="close-outline"></ion-icon>
                                CANCELAR
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Iconed Block -->

        <!-- Dialog Iconed Inline -->
        <div class="modal fade dialogbox" id="DialogIconedButtonInline" data-bs-backdrop="static" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviando $50 a John</h5>
                    </div>
                    <div class="modal-body">
                        ¿Estás seguro de eso?
                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn btn-text-danger" data-bs-dismiss="modal">
                                <ion-icon name="close-outline"></ion-icon>
                                CANCELAR
                            </a>
                            <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">
                                <ion-icon name="checkmark-outline"></ion-icon>
                                ENVIAR
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Iconed Inline -->


        <div class="section mt-2 mb-4">
            <div class="section-title">Más</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogForm">
                            <div class="in">
                                <div>Dialogo de Formulario</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogImage">
                            <div class="in">
                                <div>Dialogo de Imagen</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Dialog Form -->
        <div class="modal fade dialogbox" id="DialogForm" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Depositar Dinero</h5>
                    </div>
                    <form>
                        <div class="modal-body text-start mb-2">

                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="account1">De</label>
                                    <select class="form-control custom-select" id="account1">
                                        <option value="0">Ahorros (*** 5019)</option>
                                        <option value="1">Inversión (*** 6212)</option>
                                        <option value="2">Hipoteca (*** 5021)</option>
                                    </select>
                                </div>
                                <div class="input-info">Selecciona una cuenta bancaria</div>
                            </div>


                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label" for="text1">Ingresar Cantidad</label>
                                    <input type="text" class="form-control" id="text1" value="100">
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            <div class="btn-inline">
                                <button type="button" class="btn btn-text-secondary"
                                    data-bs-dismiss="modal">CANCELAR</button>
                                <button type="button" class="btn btn-text-primary"
                                    data-bs-dismiss="modal">DEPOSITAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- * Dialog Form -->

        <!-- Dialog Image -->
        <div class="modal fade dialogbox" id="DialogImage" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="img-fluid">
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a>
                            <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">HECHO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Dialog Image -->




    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>