<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Acordeón</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section inset mt-2">
            <div class="section-title">Insertado</div>
            <accordion>
                <accordion-item title="Elemento # 1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
            </accordion>
        </div>

        <div class="section inset mt-2 mb-2">
            <div class="section-title">Insertado con icono</div>
            <accordion>
                <accordion-item title="Elemento # 1" icon="home-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 2" icon="wallet-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 3" icon="card-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 4" icon="cash-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
            </accordion>
        </div>

        <div class="section full mt-2">
            <div class="section-title">Ancho completo</div>
            <accordion>
                <accordion-item title="Elemento # 1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
            </accordion>
        </div>

        <div class="section full mt-2 mb-2">
            <div class="section-title">Ancho completo con icono</div>
            <accordion>
                <accordion-item title="Elemento # 1" icon="home-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 2" icon="wallet-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 3" icon="card-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # 4" icon="cash-outline">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
            </accordion>
        </div>

        <div class="section inset mt-2 mb-2">
            <div class="section-title">Coloreado</div>
            <accordion>
                <accordion-item title="Elemento # primary" bg="primary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # secondary" bg="secondary">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # success" bg="success">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # danger" bg="danger">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # info" bg="info">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                <accordion-item title="Elemento # dark" bg="dark">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend, lacinia ex quis, condimentum erat. Nullam a ipsum lorem.</accordion-item>
                
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordiond3">
                            Éxito
                        </button>
                    </h2>
                    <div id="accordiond3" class="accordion-collapse collapse" data-bs-parent="#accordionExample5">
                        <div class="accordion-body">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent at augue eleifend,
                            lacinia ex quis, condimentum erat. Nullam a ipsum lorem.
                        </div>
                    </div>
                </div>
            </accordion>
        </div>

    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>