<template>
    <div class="appFooter">
        <div class="footer-title">
            Copyright © {{ $root.site.name.full }} 2024. All Rights Reserved.
        </div>
        Powered by {{ $root.site.name.regular }} 
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>