<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Encabezado de la Aplicación</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule" class="testPreview">

        <div class="section full mt-2">
            <div class="section-title">Predeterminado</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                        <div class="badge badge-danger">8</div>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Botones de texto</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                        Atrás
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        Agregar
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Múltiples Botones</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="add-circle"></ion-icon>
                    </a>
                    <a href="#" class="headerButton">
                        <ion-icon name="qr-code-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Con Botón Regular</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <span class="btn btn-primary">Agregar</span>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Sin Borde</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader no-border">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Transparente</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader transparent">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Primario</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-primary text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Secundario</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-secondary text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Peligro</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-danger text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Éxito</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-success text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Advertencia</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-warning text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Información</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-info text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Claro</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>

        <div class="section full mt-4 mb-2">
            <div class="section-title">Oscuro</div>
            <!-- Encabezado de la Aplicación -->
            <div class="appHeader bg-dark text-light">
                <div class="left">
                    <a href="#" class="headerButton">
                        <ion-icon name="chevron-back-outline"></ion-icon>
                    </a>
                </div>
                <div class="pageTitle">Título de la Página</div>
                <div class="right">
                    <a href="#" class="headerButton">
                        <ion-icon name="notifications-outline"></ion-icon>
                    </a>
                </div>
            </div>
            <!-- * Encabezado de la Aplicación -->
        </div>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuraciones</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>