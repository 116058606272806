<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Casillas de Verificación</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section mt-1">
            <div class="section-title">Bloque</div>
            <div class="card">
                <div class="card-body">

                    <div class="form-check mb-1">
                        <input type="checkbox" class="form-check-input" id="customCheckb1">
                        <label class="form-check-label" for="customCheckb1">$ 50</label>
                    </div>
                    <div class="form-check mb-1">
                        <input type="checkbox" class="form-check-input" id="customCheckb2">
                        <label class="form-check-label" for="customCheckb2">$ 100</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheckb3">
                        <label class="form-check-label" for="customCheckb3">$ 200</label>
                    </div>

                </div>
            </div>
        </div>


        <div class="section full mt-2 mb-2">
            <div class="section-title">Lista de Casillas de Verificación Completa</div>
            <div class="wide-block p-0">

                <div class="input-list">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheckc1">
                        <label class="form-check-label" for="customCheckc1">$ 50</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheckc2">
                        <label class="form-check-label" for="customCheckc2">$ 100</label>
                    </div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="customCheckc3">
                        <label class="form-check-label" for="customCheckc3">$ 200</label>
                    </div>
                </div>

            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Lista de Casillas de Verificación con Sangría</div>
            <div class="card">
                <div class="card-body p-0">

                    <div class="input-list">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="customCheckd1">
                            <label class="form-check-label" for="customCheckd1">$ 50</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="customCheckd2">
                            <label class="form-check-label" for="customCheckd2">$ 100</label>
                        </div>
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="customCheckd3">
                            <label class="form-check-label" for="customCheckd3">$ 200</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">En Línea</div>
            <div class="card">
                <div class="card-body">


                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                        <label class="form-check-label" for="inlineCheckbox1"></label>
                    </div>
                    non suscipit nisi, in mattis risus.
                    Pellentesque ullamcorper dolor et sapien egestas.

                </div>
            </div>
        </div>



    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>