<template>
    

    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Cronología</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-1 mb-2">
            <div class="section-title">Básico</div>
            <div class="card">
                <!-- Cronología -->
                <div class="timeline ms-3">
                    <div class="item">
                        <div class="dot bg-primary"></div>
                        <div class="content">
                            <h4 class="title">Pago enviado</h4>
                            <div class="text">Enviaste $50 a John</div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="dot"></div>
                        <div class="content">
                            <h4 class="title">Documentos KYC</h4>
                            <div class="text">Tu ID ha sido aprobada.</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="dot bg-info"></div>
                        <div class="content">
                            <h4 class="title">Tu tarjeta ha sido agregada</h4>
                            <div class="text">*** 5039</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="dot bg-danger"></div>
                        <div class="content">
                            <h4 class="title">Solicitudes de amistad</h4>
                            <div class="text">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar6.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar7.jpg" alt="avatar" class="imaged w24 rounded">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Cronología -->
            </div>
        </div>


        <div class="section mt- mb-2">
            <div class="section-title">Con etiquetas de tiempo</div>
            <div class="card">
                <!-- Cronología -->
                <div class="timeline timed ms-1 me-2">

                    <div class="item">
                        <span class="time">02:40 PM</span>
                        <div class="dot bg-primary"></div>
                        <div class="content">
                            <h4 class="title">Pago enviado</h4>
                            <div class="text">Enviaste $50 a John</div>
                        </div>
                    </div>
                    <div class="item">
                        <span class="time">09:00 PM</span>
                        <div class="dot"></div>
                        <div class="content">
                            <h4 class="title">Documentos KYC</h4>
                            <div class="text">Tu ID ha sido aprobada.</div>
                        </div>
                    </div>
                    <div class="item">
                        <span class="time">02:40 AM</span>
                        <div class="dot bg-info"></div>
                        <div class="content">
                            <h4 class="title">Tu tarjeta ha sido agregada</h4>
                            <div class="text">*** 5039</div>
                        </div>
                    </div>
                    <div class="item">
                        <span class="time">06:24 PM</span>
                        <div class="dot bg-danger"></div>
                        <div class="content">
                            <h4 class="title">Solicitudes de amistad</h4>
                            <div class="text">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar6.jpg" alt="avatar" class="imaged w24 rounded">
                                <img src="@/assets/img/sample/avatar/avatar7.jpg" alt="avatar" class="imaged w24 rounded">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- * Cronología -->
            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->
</template>