<template>
    

    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Tabla</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Predeterminada</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end text-primary">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end text-primary">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end text-primary">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>



        <div class="section mt-2 mb-2">
            <div class="section-title">Tabla con rayas</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>



        <div class="section mt-2 mb-2">
            <div class="section-title">Primaria</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-primary">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Secundaria</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-secondary">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Éxito</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-success">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Peligro</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-danger">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Advertencia</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-warning">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Información</div>
            <div class="card">

                <div class="table-responsive">
                    <table class="table bg-info">
                        <thead>
                            <tr>
                                <th scope="col">ID de usuario</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Título</th>
                                <th scope="col" class="text-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">15323</th>
                                <td>John</td>
                                <td>Manager</td>
                                <td class="text-end">$ 509.40</td>
                            </tr>
                            <tr>
                                <th scope="row">55212</th>
                                <td>Mark</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 230.95</td>
                            </tr>
                            <tr>
                                <th scope="row">44623</th>
                                <td>Jane</td>
                                <td>Usuario</td>
                                <td class="text-end">$ 3,420.50</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>