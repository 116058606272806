<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Gráficos</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="section-title">Gráficos Apex</div>
            <div class="card">
                <div class="card-body">
                    SombreroWallet usando <a href="https://apexcharts.com/" target="_blank" rel="nofollow">Gráficos Apex</a>.
                    Gráficos de código abierto modernos e interactivos fáciles de personalizar
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-3">
            <div class="section-title">Línea</div>
            <div class="card">
                <div class="card-body">
                    <div id="chart-line"></div>
                </div>
            </div>
        </div>

        <div class="section mt-3">
            <div class="row">
                <div class="col">
                    <div class="section-title">Pastel</div>
                    <div class="card">
                        <div class="card-body pe-0 ps-0">
                            <div id="chart-pie"></div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="section-title">Dona</div>
                    <div class="card">
                        <div class="card-body pe-0 ps-0">
                            <div id="chart-donut"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="section mt-3">
            <div class="section-title">Barra</div>
            <div class="card">
                <div class="card-body">
                    <div id="chart-bar"></div>
                </div>
            </div>
        </div>

        <div class="section mt-3 mb-3">
            <div class="section-title">Velas</div>
            <div class="card">
                <div class="card-body">
                    <div id="chart-candlestick"></div>
                </div>
            </div>
        </div>

        <div class="section mb-3">
            <a href="https://apexcharts.com/" target="_blank" rel="nofollow" class="btn btn-primary btn-lg btn-block">Más ejemplos</a>
        </div>

    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Descripción general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>

<script>
import ApexCharts from 'apexcharts'

export default {
    mounted() {
        this.runScripts()
    },
    methods: {
        runScripts() {
            
            // Demo Charts

            // Chart Line
            var optionChartLine = {
                series: [{
                    data: [555, 1222, 777, 888, 555, 888, 999, 1222]
                }],
                chart: {
                    type: 'area',
                    width: '100%',
                    height: 150,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#6236FF'],
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
            };
            var chartLine = new ApexCharts(document.querySelector("#chart-line"), optionChartLine);
            chartLine.render();


            // Chart Pie
            var optionChartPie = {
                series: [44, 55, 13, 43, 22],
                chart: {
                    sparkline: {
                        enabled: true
                    },
                    type: 'pie',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            };

            var chartPie = new ApexCharts(document.querySelector("#chart-pie"), optionChartPie);
            chartPie.render();

            // Chart Donut
            var optionChartDonut = {
                series: [44, 55, 13, 43, 22],
                chart: {
                    sparkline: {
                        enabled: true
                    },
                    type: 'donut',
                },
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            };

            var chartDonut = new ApexCharts(document.querySelector("#chart-donut"), optionChartDonut);
            chartDonut.render();


            // Chart Bar
            var optionChartBar = {
                series: [{
                    data: [555, 1222, 777, 888, 555, 888, 999, 1222]
                }],
                chart: {
                    type: 'bar',
                    width: '100%',
                    height: 150,
                    sparkline: {
                        enabled: true
                    }
                },
                stroke: {
                    width: 2,
                },
                colors: ['#6236FF'],
                labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E', 'Team F', 'Team G', 'Team H'],
            };
            var chartBar = new ApexCharts(document.querySelector("#chart-bar"), optionChartBar);
            chartBar.render();



            // Chart Radar
            var optionChartCandleStick = {
                series: [{
                    data: [{
                        x: new Date(1538778600000),
                        y: [6629.81, 6650.5, 6623.04, 6633.33]
                    },
                    {
                        x: new Date(1538780400000),
                        y: [6632.01, 6643.59, 6620, 6630.11]
                    },
                    {
                        x: new Date(1538782200000),
                        y: [6630.71, 6648.95, 6623.34, 6635.65]
                    },
                    {
                        x: new Date(1538784000000),
                        y: [6635.65, 6651, 6629.67, 6638.24]
                    },
                    {
                        x: new Date(1538785800000),
                        y: [6638.24, 6640, 6620, 6624.47]
                    },
                    {
                        x: new Date(1538787600000),
                        y: [6624.53, 6636.03, 6621.68, 6624.31]
                    },
                    {
                        x: new Date(1538789400000),
                        y: [6624.61, 6632.2, 6617, 6626.02]
                    },
                    {
                        x: new Date(1538791200000),
                        y: [6627, 6627.62, 6584.22, 6603.02]
                    },
                    {
                        x: new Date(1538793000000),
                        y: [6605, 6608.03, 6598.95, 6604.01]
                    },
                    {
                        x: new Date(1538794800000),
                        y: [6604.5, 6614.4, 6602.26, 6608.02]
                    },
                    {
                        x: new Date(1538796600000),
                        y: [6608.02, 6610.68, 6601.99, 6608.91]
                    },
                    {
                        x: new Date(1538798400000),
                        y: [6608.91, 6618.99, 6608.01, 6612]
                    },
                    {
                        x: new Date(1538800200000),
                        y: [6612, 6615.13, 6605.09, 6612]
                    },
                    {
                        x: new Date(1538802000000),
                        y: [6612, 6624.12, 6608.43, 6622.95]
                    },
                    {
                        x: new Date(1538803800000),
                        y: [6623.91, 6623.91, 6615, 6615.67]
                    },
                    {
                        x: new Date(1538805600000),
                        y: [6618.69, 6618.74, 6610, 6610.4]
                    },
                    {
                        x: new Date(1538807400000),
                        y: [6611, 6622.78, 6610.4, 6614.9]
                    },
                    {
                        x: new Date(1538809200000),
                        y: [6614.9, 6626.2, 6613.33, 6623.45]
                    },
                    {
                        x: new Date(1538811000000),
                        y: [6623.48, 6627, 6618.38, 6620.35]
                    },
                    {
                        x: new Date(1538812800000),
                        y: [6619.43, 6620.35, 6610.05, 6615.53]
                    },
                    {
                        x: new Date(1538814600000),
                        y: [6615.53, 6617.93, 6610, 6615.19]
                    },
                    {
                        x: new Date(1538816400000),
                        y: [6615.19, 6621.6, 6608.2, 6620]
                    },
                    {
                        x: new Date(1538818200000),
                        y: [6619.54, 6625.17, 6614.15, 6620]
                    },
                    {
                        x: new Date(1538820000000),
                        y: [6620.33, 6634.15, 6617.24, 6624.61]
                    },
                    {
                        x: new Date(1538821800000),
                        y: [6625.95, 6626, 6611.66, 6617.58]
                    },
                    {
                        x: new Date(1538823600000),
                        y: [6619, 6625.97, 6595.27, 6598.86]
                    },
                    {
                        x: new Date(1538825400000),
                        y: [6598.86, 6598.88, 6570, 6587.16]
                    },
                    {
                        x: new Date(1538827200000),
                        y: [6588.86, 6600, 6580, 6593.4]
                    },
                    {
                        x: new Date(1538829000000),
                        y: [6593.99, 6598.89, 6585, 6587.81]
                    },
                    {
                        x: new Date(1538830800000),
                        y: [6587.81, 6592.73, 6567.14, 6578]
                    },
                    {
                        x: new Date(1538832600000),
                        y: [6578.35, 6581.72, 6567.39, 6579]
                    },
                    {
                        x: new Date(1538834400000),
                        y: [6579.38, 6580.92, 6566.77, 6575.96]
                    },
                    {
                        x: new Date(1538836200000),
                        y: [6575.96, 6589, 6571.77, 6588.92]
                    },
                    {
                        x: new Date(1538838000000),
                        y: [6588.92, 6594, 6577.55, 6589.22]
                    },
                    {
                        x: new Date(1538839800000),
                        y: [6589.3, 6598.89, 6589.1, 6596.08]
                    }
                    ]
                }],
                chart: {
                    type: 'candlestick',
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    candlestick: {
                        colors: {
                            upward: '#1DCC70',
                            downward: '#FF396F'
                        }
                    }
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    tooltip: {
                        enabled: true
                    }
                }
            };

            var chartCandleStick = new ApexCharts(document.querySelector("#chart-candlestick"), optionChartCandleStick);
            chartCandleStick.render();


        }
    }
}
</script>