<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Entradas</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Básico</div>
            <div class="card">
                <div class="card-body">

                    <form>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid1">ID de Usuario</label>
                                <input type="text" class="form-control" id="userid1" placeholder="Ingresa un ID de Usuario">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="amount1">Monto</label>
                                <input type="text" class="form-control" id="amount1" placeholder="Ingresa un Monto">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Animado</div>
            <div class="card">
                <div class="card-body">
                    <div class="mb-05">
                        Escribe algo para activar la animación.
                    </div>
                    <form>

                        <div class="form-group basic animated">
                            <div class="input-wrapper">
                                <label class="label" for="userid2">ID de Usuario</label>
                                <input type="text" class="form-control" id="userid2" placeholder="ID de Usuario">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic animated">
                            <div class="input-wrapper">
                                <label class="label" for="amount2">Monto</label>
                                <input type="text" class="form-control" id="amount2" placeholder="Monto">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                    </form>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Texto Adicional</div>
            <div class="card">
                <div class="card-body">

                    <form>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="userid3">ID de Usuario</label>
                                <input type="text" class="form-control" id="userid3" placeholder="Ingresa un ID de Usuario">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Por favor ingresa un ID de usuario</div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="amount3">Monto</label>
                                <input type="text" class="form-control" id="amount3" placeholder="Ingresa un monto">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                            <div class="input-info">Mín $ 50 - Máx $ 500</div>
                        </div>
                    </form>

                </div>
            </div>
        </div>



        <div class="section mt-2">
            <div class="section-title">Entrada de Carga</div>
            <div class="card">
                <div class="card-body">

                    <form>
                        <div class="custom-file-upload" id="fileUpload1">
                            <input type="file" id="fileuploadInput" accept=".png, .jpg, .jpeg">
                            <label for="fileuploadInput">
                                <span>
                                    <strong>
                                        <ion-icon name="arrow-up-circle-outline"></ion-icon>
                                        <i>Cargar una Foto</i>
                                    </strong>
                                </span>
                            </label>
                        </div>

                    </form>

                </div>
            </div>
        </div>



        <div class="section mt-2 mb-2">
            <div class="section-title">Tipos</div>
            <div class="card">
                <div class="card-body">
                    <form>
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="text4">Texto</label>
                                <input type="text" class="form-control" id="text4" placeholder="Entrada de Texto">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email4">Correo Electrónico</label>
                                <input type="email" class="form-control" id="email4" placeholder="Entrada de Correo Electrónico">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="select4">Seleccionar</label>
                                <select class="form-control custom-select" id="select4">
                                    <option value="1">Seleccionar 1</option>
                                    <option value="2">Seleccionar 2</option>
                                    <option value="3">Seleccionar 3</option>
                                    <option value="4">Seleccionar 4</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="password4">Contraseña</label>
                                <input type="password" class="form-control" id="password4" autocomplete="off"
                                    placeholder="Entrada de Contraseña">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="phone4">Teléfono</label>
                                <input type="tel" class="form-control" id="phone4" placeholder="Entrada de Teléfono">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="textarea4">Área de Texto</label>
                                <textarea id="textarea4" rows="2" class="form-control"
                                    placeholder="Área de Texto"></textarea>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Entradas Enmarcadas</div>
            <div class="card">
                <div class="card-body">
                    <form>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="text4b">Texto</label>
                                <input type="text" class="form-control" id="text4b" placeholder="Entrada de Texto">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="email4b">Correo Electrónico</label>
                                <input type="email" class="form-control" id="email4b" placeholder="Entrada de Correo Electrónico">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="select4b">Seleccionar</label>
                                <select class="form-control custom-select" id="select4b">
                                    <option value="1">Seleccionar 1</option>
                                    <option value="2">Seleccionar 2</option>
                                    <option value="3">Seleccionar 3</option>
                                    <option value="4">Seleccionar 4</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="password4b">Contraseña</label>
                                <input type="password" autocomplete="off" class="form-control" id="password4b"
                                    placeholder="Entrada de Contraseña">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="phone4b">Teléfono</label>
                                <input type="tel" class="form-control" id="phone4b" placeholder="Entrada de Teléfono">
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="label" for="textarea4b">Área de Texto</label>
                                <textarea id="textarea4b" rows="2" class="form-control"
                                    placeholder="Área de Texto"></textarea>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>