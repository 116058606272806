<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Tarjetas</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    Este es algo de texto dentro del cuerpo de una tarjeta.
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta</h5>
                    <h6 class="card-subtitle mb-1">Subtítulo de la tarjeta</h6>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <img src="@/assets/img/sample/photo/wide1.jpg" class="card-img-top" alt="image">
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta</h5>
                    <h6 class="card-subtitle mb-1">Subtítulo de la tarjeta</h6>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <ul class="listview flush transparent simple-listview">
                    <li>Artículo 1</li>
                    <li>Artículo 2</li>
                    <li>Artículo 3</li>
                </ul>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Configuraciones</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Actualizar</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Contactos</div>
                                <span class="badge badge-primary">8</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <ul class="listview flush transparent image-listview">
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-primary">
                                <ion-icon name="card-outline"></ion-icon>
                            </div>
                            <div class="in">
                                Artículo 1
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-danger">
                                <ion-icon name="cash-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>Artículo 2</div>
                                <span class="text-muted">Texto</span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-success">
                                <ion-icon name="wallet-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>Artículo 3</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <div class="section mt-2">
            <div class="card">
                <div class="card-header">
                    Encabezado de la tarjeta
                </div>
                <div class="card-body">
                    <h5 class="card-title">Título</h5>
                    <p class="card-text">Con texto de apoyo debajo como una introducción natural a contenido adicional.</p>
                    <a href="#" class="btn btn-primary">Ir a algún lugar</a>
                </div>
                <div class="card-footer">
                    Este es el pie de página de la tarjeta
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <div class="card-header">
                    Cita
                </div>
                <div class="card-body">
                    <blockquote class="blockquote mb-0">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
                        <footer class="blockquote-footer">Alguien famoso en <cite title="Source Title">Título de la
                                Fuente</cite></footer>
                    </blockquote>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card text-center">
                <div class="card-header">
                    Destacado
                </div>
                <div class="card-body">
                    <h5 class="card-title">Tratamiento de título especial</h5>
                    <p class="card-text">Con texto de apoyo debajo como una introducción natural a contenido adicional.</p>
                    <a href="#" class="btn btn-primary">Ir a algún lugar</a>
                </div>
                <div class="card-footer text-muted">
                    Hace 2 días
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card">
                <img src="@/assets/img/sample/photo/wide1.jpg" class="card-img-top" alt="image">
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta</h5>
                    <p class="card-text">Esta es una tarjeta más amplia con texto de apoyo debajo como una introducción
                        natural a contenido adicional. Este contenido es un poco más largo.</p>
                    <p class="card-text"><small>Última actualización hace 3 minutos</small></p>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="card bg-dark text-white">
                <img src="@/assets/img/sample/photo/wide1.jpg" class="card-img overlay-img" alt="image">
                <div class="card-img-overlay">
                    <h5 class="card-title">Título de la tarjeta</h5>
                    <p class="card-text">Algún texto de la tarjeta aquí y contenido de texto más natural.</p>
                    <p class="card-text"><small>Última actualización hace 3 minutos</small></p>
                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="card text-white bg-primary mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta principal</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-secondary mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta secundaria</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-success mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta de éxito</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-danger mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta de peligro</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-warning mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta de advertencia</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-info mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta de información</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card bg-light mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta clara</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
            <div class="card text-white bg-dark mb-2">
                <div class="card-header">Encabezado</div>
                <div class="card-body">
                    <h5 class="card-title">Título de la tarjeta oscura</h5>
                    <p class="card-text">Algo de texto de ejemplo rápido para desarrollar el título de la tarjeta y formar
                        la mayor parte del contenido de la tarjeta.</p>
                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuraciones</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->


</template>