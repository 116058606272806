<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Conmutadores</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="full-height">

        <div class="section mt-1 mb-2">
            <div class="section-title">Con icono</div>
            <div class="card">

                <ul class="listview image-listview transparent flush">
                    <li>
                        <div class="item">
                            <div class="icon-box bg-primary">
                                <ion-icon name="notifications-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    Notificación
                                </div>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1">
                                    <label class="form-check-label" for="SwitchCheckDefault1"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item">
                            <div class="icon-box bg-danger">
                                <ion-icon name="mail-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>Boletín</div>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault2" checked />
                                    <label class="form-check-label" for="SwitchCheckDefault2"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Texto</div>
            <div class="card">

                <ul class="listview simple-listview transparent flush">
                    <li>
                        <div>Conmutador 1</div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="SwitchCheckDefault3">
                            <label class="form-check-label" for="SwitchCheckDefault3"></label>
                        </div>
                    </li>
                    <li>
                        <div>Conmutador 2</div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="SwitchCheckDefault4" checked />
                            <label class="form-check-label" for="SwitchCheckDefault4"></label>
                        </div>
                    </li>
                    <li>
                        <div>Conmutador 3</div>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="SwitchCheckDefault5">
                            <label class="form-check-label" for="SwitchCheckDefault5"></label>
                        </div>
                    </li>
                </ul>

            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->
</template>