<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Insignias</div>
        <div class="right">
            <a href="#" class="headerButton">
                <ion-icon name="notifications-outline"></ion-icon>
                <span class="badge badge-danger">5</span>
            </a>
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="full-height">


        <div class="section mt-2">
            <div class="section-title">Colores</div>
            <div class="card">
                <div class="card-body">
                    <span class="badge badge-primary">Primario</span>
                    <span class="badge badge-secondary">Secundario</span>
                    <span class="badge badge-success">Éxito</span>
                    <div class="mb-05"></div>
                    <span class="badge badge-danger">Peligro</span>
                    <span class="badge badge-warning">Advertencia</span>
                    <span class="badge badge-info">Información</span>
                    <div class="mb-05"></div>
                    <span class="badge badge-light">Claro</span>
                    <span class="badge badge-dark">Oscuro</span>

                    <div class="mt-3"></div>
                    <h4 class="mb-05">Insignias vacías</h4>
                    <span class="badge badge-primary badge-empty"></span>
                    <span class="badge badge-secondary badge-empty"></span>
                    <span class="badge badge-success badge-empty"></span>
                    <span class="badge badge-danger badge-empty"></span>
                    <span class="badge badge-warning badge-empty"></span>
                    <span class="badge badge-info badge-empty"></span>
                    <span class="badge badge-dark badge-empty"></span>
                    <span class="badge badge-light badge-empty"></span>
                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Ejemplo de vista de lista</div>
            <div class="card">
                <ul class="listview image-listview transparent flush">
                    <li>
                        <div class="item">
                            <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                            <div class="in">
                                <div>Henry Richardson</div>
                                <span class="badge badge-primary">6</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item">
                            <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                            <div class="in">
                                <div>Diane Lansdowne</div>
                                <span class="badge badge-secondary">NUEVO</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item">
                            <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                            <div class="in">
                                <div>Lucas Simoes</div>
                                <span class="badge badge-success">86</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="wallet-outline"></ion-icon>
                <strong>Artículo 1</strong>
                <span class="badge badge-primary">6</span>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="cash-outline"></ion-icon>
                <strong>Artículo 2</strong>
                <span class="badge badge-success">52</span>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="earth-outline"></ion-icon>
                <strong>Artículo 3</strong>
                <span class="badge badge-danger">52</span>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="qr-code-outline"></ion-icon>
                <strong>Artículo 4</strong>
                <span class="badge badge-warning">8</span>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="notifications-outline"></ion-icon>
                <strong>Artículo 5</strong>
                <span class="badge badge-info">5</span>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>