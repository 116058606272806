<template>
    <div class="section mt-4">
        <div class="section-heading">
            <h2 class="title">Mi portafolio</h2>
            <a href="/crypto/portfolio" class="link">Ver todo</a>
        </div>
        <div class="card">
            <portfolio-list :assets="assets" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        assets: {
            type: Array,
            default: [],
        },
        maxl: {
            type: Number,
            default: 5,
        }
    },
    methods: {
    }
}
</script>