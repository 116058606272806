<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Modal & Paneles</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="full-height">

        <div class="section mt-2">
            <div class="section-title">Modal</div>
            <div class="card">
                <div class="card-body">
                    <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#ModalBasic">Abrir
                        Modal</a>
                </div>
            </div>
        </div>

        <!-- Modal Básico -->
        <div class="modal fade modalbox" id="ModalBasic" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título del Modal</h5>
                        <a href="#" data-bs-dismiss="modal">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget finibus
                            fermentum, velit metus maximus erat, nec sodales elit justo vitae sapien. Sed fermentum
                            varius erat, et dictum lorem. Cras pulvinar vestibulum purus sed hendrerit. Praesent et
                            auctor dolor. Ut sed ultrices justo. Fusce tortor erat, scelerisque sit amet diam rhoncus,
                            cursus dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida purus.
                        </p>
                        <p>
                            Donec in justo urna. Fusce pretium quam sed viverra blandit. Vivamus a facilisis lectus.
                            Nunc non aliquet nulla. Aenean arcu metus, dictum tincidunt lacinia quis, efficitur vitae
                            dui. Integer id nisi sit amet leo rutrum placerat in ac tortor. Duis sed fermentum mi, ut
                            vulputate ligula.
                        </p>
                        <p>
                            Vivamus eget sodales elit, cursus scelerisque leo. Suspendisse lorem leo, sollicitudin
                            egestas interdum sit amet, sollicitudin tristique ex. Class aptent taciti sociosqu ad litora
                            torquent per conubia nostra, per inceptos himenaeos. Phasellus id ultricies eros. Praesent
                            vulputate interdum dapibus. Duis varius faucibus metus, eget sagittis purus consectetur in.
                            Praesent fringilla tristique sapien, et maximus tellus dapibus a. Quisque nec magna dapibus
                            sapien iaculis consectetur. Fusce in vehicula arcu. Aliquam erat volutpat. Class aptent
                            taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Modal Básico -->



        <div class="section mt-2">
            <div class="section-title">Panel Izquierdo</div>
            <div class="card">
                <div class="card-body">
                    <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PanelLeft">Abrir Panel
                        Izquierdo</a>
                </div>
            </div>
        </div>

        <!-- Panel izquierdo -->
        <div class="modal fade panelbox panelbox-left" id="PanelLeft" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título del Panel Izquierdo</h5>
                        <a href="#" data-bs-dismiss="modal">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        Cuerpo del Panel
                    </div>
                </div>
            </div>
        </div>
        <!-- * Panel izquierdo -->

        <div class="section mt-2">
            <div class="section-title">Panel Derecho</div>
            <div class="card">
                <div class="card-body">
                    <a href="#" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#PanelRight">Abrir Panel
                        Derecho</a>
                </div>
            </div>
        </div>

        <!-- Panel derecho -->
        <div class="modal fade panelbox panelbox-right" id="PanelRight" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título del Panel Derecho</h5>
                        <a href="#" data-bs-dismiss="modal">Cerrar</a>
                    </div>
                    <div class="modal-body">
                        Cuerpo del Panel
                    </div>
                </div>
            </div>
        </div>
        <!-- * Panel derecho -->



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Vista General</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>