<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Intercambio P2P
        </div>
        <div class="right">
            <a href="#" class="headerButton" data-bs-toggle="modal" data-bs-target="#actionSheetForm">
                <ion-icon name="add-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Encabezado Extra -->
    <div class="extraHeader pe-0 ps-0">
        <ul class="nav nav-tabs lined" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#waiting" role="tab">
                    Pendientes
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#paid" role="tab">
                    Pagadas
                </a>
            </li>
        </ul>
    </div>
    <!-- * Encabezado Extra -->


    <!-- Hoja de Acción de Formulario -->
    <div class="modal fade action-sheet" id="actionSheetForm" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Agregar Nueva Factura</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">

                        <form>
                            <div class="form-group basic">
                                <label class="label">ID de Factura</label>
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1">#</span>
                                    <input type="text" class="form-control" placeholder="Ingresa una cantidad" value="41512">
                                </div>
                                <div class="input-info">Ingresa el ID de la factura que deseas agregar.</div>
                            </div>


                            <div class="form-group basic">
                                <button type="button" class="btn btn-primary btn-block btn-lg"
                                    data-bs-dismiss="modal">Agregar</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Hoja de Acción de Formulario -->


    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule" class="extra-header-active full-height">

        <div class="section tab-content mt-2 mb-1">

            <!-- pestaña esperando -->
            <div class="tab-pane fade show active" id="waiting" role="tabpanel">
                <div class="row">
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 14</div>
                            <p>Suscripción Mensual Prime</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/2.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 8</div>
                            <p>Pago Mensual </p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/3.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 15</div>
                            <p>Suscripción a Envato Elements</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/4.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 49</div>
                            <p>Tarifas de suscripción</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/5.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 12</div>
                            <p>Membresía Pro</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 14</div>
                            <p>Suscripción Mensual Prime</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/2.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 8</div>
                            <p>Pago Mensual </p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/3.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 15</div>
                            <p>Suscripción a Envato Elements</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">PAGAR AHORA</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- * pestaña esperando -->



            <!-- pestaña pagada -->
            <div class="tab-pane fade" id="paid" role="tabpanel">
                <div class="row">
                    <div class="col-6 mb-2">
                        <div class="bill-box">
                            <div class="img-wrapper">
                                <img src="@/assets/img/sample/brand/1.jpg" alt="img" class="image-block imaged w48">
                            </div>
                            <div class="price">$ 14</div>
                            <p>Suscripción Mensual Prime</p>
                            <a href="#" class="btn btn-primary btn-block btn-sm">DETALLES</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- * pestaña pagada -->

        </div>

    </div>
    <!-- * Cápsula de la Aplicación -->


    <app-menu-bottom />

</template>