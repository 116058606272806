<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Radios</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-1">
            <div class="section-title">Bloque</div>
            <div class="card">
                <div class="card-body">

                    <div class="form-check mb-1">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                        <label class="form-check-label" for="flexRadioDefault1">
                            $ 50
                        </label>
                    </div>
                    <div class="form-check mb-1">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                        <label class="form-check-label" for="flexRadioDefault2">
                            $ 100
                        </label>
                    </div>
                    <div class="form-check mb-1">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3">
                        <label class="form-check-label" for="flexRadioDefault3">
                            $ 250
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4">
                        <label class="form-check-label" for="flexRadioDefault4">
                            $ 500
                        </label>
                    </div>

                </div>
            </div>
        </div>

        <div class="section full mt-2 mb-2">
            <div class="section-title">Lista de radios</div>
            <div class="wide-block p-0">

                <div class="input-list">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioList" id="radioList1">
                        <label class="form-check-label" for="radioList1">$ 50</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioList" id="radioList2">
                        <label class="form-check-label" for="radioList2">$ 100</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioList" id="radioList3">
                        <label class="form-check-label" for="radioList3">$ 250</label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="radioList" id="radioList4">
                        <label class="form-check-label" for="radioList4">$ 500</label>
                    </div>
                </div>

            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Lista de radios con sangría</div>
            <div class="card">
                <div class="card-body p-0">

                    <div class="input-list">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="radioList2" id="radioList1b">
                            <label class="form-check-label" for="radioList1b">$ 50</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="radioList2" id="radioList2b">
                            <label class="form-check-label" for="radioList2b">$ 100</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="radioList2" id="radioList3b">
                            <label class="form-check-label" for="radioList3b">$ 250</label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="radioList2" id="radioList4b">
                            <label class="form-check-label" for="radioList4b">$ 500</label>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="section full mb-2 mt-2">
            <div class="section-title">En línea</div>
            <div class="wide-block pt-2 pb-2">

                Aenean hendrerit porttitor dolor id elementum.
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="radioInline" id="radioInlineDefault1">
                    <label class="form-check-label" for="radioInlineDefault1"></label>
                </div>
                fringilla ex eget, ultrices urna. Donec elementum urna maximus suscipit vulputate.
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="radioInline" id="radioInlineDefault2">
                    <label class="form-check-label" for="radioInlineDefault2"></label>
                </div>
                Proin in congue nunc.

            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>