<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Cajas de Contenido</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            Bloque de contenido predeterminado con relleno automático a la izquierda y a la derecha.
        </div>

        <div class="section mt-5">
            <div class="section-title">Título de la Sección</div>
            Bloque de contenido con título.
        </div>

        <div class="section full mt-5">
            Bloque de contenido de ancho completo sin relleno.<br>
        </div>


        <div class="section full mt-5">
            <div class="wide-block pt-2 pb-2">
                Bloque amplio de ancho completo con fondo
            </div>
        </div>

        <div class="section inset mt-5">
            <div class="wide-block pt-2 pb-2">
                Bloque amplio con sangría
            </div>
        </div>

        <div class="section full mt-5">
            <div class="section-title">Título de la Sección</div>
            <div class="wide-block pt-2 pb-2">
                Bloque amplio con título
            </div>
        </div>


        <div class="section full mt-5">
            <div class="section-title medium">Título de Sección Medio</div>
            <div class="wide-block pt-2 pb-2">
                Ejemplo de título de sección medio.<br>
            </div>
        </div>

        <div class="section full mt-5">
            <div class="section-title large">Título de Sección Grande</div>
            <div class="wide-block pt-2 pb-2">
                Ejemplo de título de sección grande.<br>
            </div>
        </div>

        <div class="section inset mt-5">
            <div class="section-title">Título de Sección con Sangría</div>
            <div class="wide-block pt-2 pb-2">
                Bloque amplio con sangría y título.
            </div>
        </div>

        <div class="section mt-5">
            <div class="section-title">Pie de Página y Encabezado de Contenido</div>
            <div class="content-header mb-05">Texto del Encabezado</div>
            Texto
            <div class="content-footer mt-05">Texto del Pie de Página</div>
        </div>

        <div class="section full mt-5">
            <div class="wide-block pt-2 pb-2">
                <div class="content-header mb-05">Texto del Encabezado</div>
                Bloque amplio con encabezado y pie de página de contenido.
                <div class="content-footer mt-05">Texto del Pie de Página</div>
            </div>
        </div>

        <div class="section inset mt-5">
            <div class="wide-block pt-2 pb-2">
                <div class="content-header mb-05">Texto del Encabezado</div>
                Bloque amplio con sangría con encabezado y pie de página.
                <div class="content-footer mt-05">Texto del Pie de Página</div>
            </div>
        </div>

        <div class="section full mt-5 mb-3">
            <div class="content-header mb-05">Texto del Encabezado</div>
            <div class="wide-block pt-2 pb-2">
                Bloque amplio con pie de página y encabezado externos.
            </div>
            <div class="content-footer mt-05">Texto del Pie de Página</div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>