<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Alertas</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-1 mb-2">
            <div class="section-title">Predeterminado</div>
            <div class="card">
                <div class="card-body">

                    <div class="alert alert-primary mb-2" role="alert">
                        ¡Una alerta simple!
                    </div>

                    <div class="alert alert-primary alert-dismissible fade show mb-2" role="alert">
                        ¡Alerta descartable!
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                    <div class="alert alert-outline-primary alert-dismissible fade show" role="alert">
                        ¡Alerta con contorno!
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Con imagen</div>
            <div class="card">
                <div class="card-body">

                    <div class="alert alert-imaged alert-primary alert-dismissible fade show mb-2" role="alert">
                        <div class="img-wrap">
                            <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="avatar" class="imaged w24 rounded">
                        </div>
                        <div>
                            <strong>John</strong> te envió <strong>$200</strong>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                    <div class="alert alert-imaged alert-outline-primary alert-dismissible fade show" role="alert">
                        <div class="img-wrap">
                            <img src="@/assets/img/sample/avatar/avatar5.jpg" alt="avatar" class="imaged w24 rounded">
                        </div>
                        <div>
                            <strong>John</strong> te envió <strong>$200</strong>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Con icono</div>
            <div class="card">
                <div class="card-body">

                    <div class="alert alert-imaged alert-primary alert-dismissible fade show mb-2" role="alert">
                        <div class="icon-wrap">
                            <ion-icon name="cash-outline"></ion-icon>
                        </div>
                        <div>
                            <strong>John</strong> te envió <strong>$200</strong>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                    <div class="alert alert-imaged alert-outline-primary alert-dismissible fade show mb-2" role="alert">
                        <div class="icon-wrap">
                            <ion-icon name="cash-outline"></ion-icon>
                        </div>
                        <div>
                            <strong>John</strong> te envió <strong>$200</strong>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                    <div class="alert alert-imaged alert-success alert-dismissible fade show" role="alert">
                        <div class="icon-wrap">
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <div>
                            Tu tarjeta ha sido agregada.
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Colores</div>
            <div class="card">
                <div class="card-body">

                    <div class="alert alert-primary mb-1" role="alert">
                        ¡Una alerta primaria simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-primary mb-1" role="alert">
                        ¡Una alerta primaria simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-secondary mb-1" role="alert">
                        ¡Una alerta secundaria simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-secondary mb-1" role="alert">
                        ¡Una alerta secundaria simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-success mb-1" role="alert">
                        ¡Una alerta de éxito simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-success mb-1" role="alert">
                        ¡Una alerta de éxito simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-danger mb-1" role="alert">
                        ¡Una alerta de peligro simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-danger mb-1" role="alert">
                        ¡Una alerta de peligro simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-warning mb-1" role="alert">
                        ¡Una alerta de advertencia simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-warning mb-1" role="alert">
                        ¡Una alerta de advertencia simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-info mb-1" role="alert">
                        ¡Una alerta de información simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-info mb-1" role="alert">
                        ¡Una alerta de información simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-light mb-1" role="alert">
                        ¡Una alerta clara simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-dark mb-1" role="alert">
                        ¡Una alerta oscura simple—¡échale un vistazo!
                    </div>
                    <div class="alert alert-outline-dark mb-1" role="alert">
                        ¡Una alerta oscura simple—¡échale un vistazo!
                    </div>

                </div>
            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuraciones</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>