<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Menú Inferior</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section full mt-2">
            <div class="section-title">Predeterminado</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Solo icono</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>



        <div class="section full mt-4">
            <div class="section-title">Botón de acción</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <div class="action-button">
                            <ion-icon name="arrow-up-outline"></ion-icon>
                        </div>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Botón de acción grande</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <div class="action-button large">
                            <ion-icon name="arrow-up-outline"></ion-icon>
                        </div>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>


        <div class="section full mt-4">
            <div class="section-title">Sin Borde</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu no-border" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>


        <div class="section full mt-4">
            <div class="section-title">Primario</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-primary text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Secundario</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-secondary text-light"
                style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Éxito</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-success text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Peligro</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-danger text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Advertencia</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-warning text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Información</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-info text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4">
            <div class="section-title">Claro</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>

        <div class="section full mt-4 mb-2">
            <div class="section-title">Oscuro</div>
            <!-- Menú Inferior de la Aplicación -->
            <div class="appBottomMenu bg-dark text-light" style="position: relative; z-index: 0; padding-bottom: 0;">
                <a href="#" class="item active">
                    <div class="col">
                        <ion-icon name="pulse-outline"></ion-icon>
                        <strong>Artículo 1</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="card-outline"></ion-icon>
                        <strong>Artículo 2</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="cash-outline"></ion-icon>
                        <strong>Artículo 3</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="calendar-outline"></ion-icon>
                        <strong>Artículo 4</strong>
                    </div>
                </a>
                <a href="#" class="item">
                    <div class="col">
                        <ion-icon name="settings-outline"></ion-icon>
                        <strong>Artículo 5</strong>
                    </div>
                </a>
            </div>
            <!-- * Menú Inferior de la Aplicación -->
        </div>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuraciones</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>