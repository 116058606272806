<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Vista de Lista</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="listview-title mt-2">Vista de Lista Simple Completa</div>
        <ul class="listview simple-listview">
            <li>John Fonseca</li>
            <li>Sophie</li>
            <li>Frank</li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista Simple Insertada</div>
        <ul class="listview simple-listview inset">
            <li>John Fonseca</li>
            <li>Sophie</li>
            <li>Frank</li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces Completa</div>
        <ul class="listview link-listview">
            <li>
                <a href="#">
                    John Fonseca
                </a>
            </li>
            <li>
                <a href="#">
                    Sophie Silverton
                    <span class="text-muted">Texto</span>
                </a>
            </li>
            <li>
                <a href="#">
                    Frank Sjögren
                    <span class="badge badge-primary">3</span>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces Insertada</div>
        <ul class="listview link-listview inset">
            <li>
                <a href="#">
                    John Fonseca
                </a>
            </li>
            <li>
                <a href="#">
                    Sophie Silverton
                    <span class="text-muted">Texto</span>
                </a>
            </li>
            <li>
                <a href="#">
                    Frank Sjögren
                    <span class="badge badge-primary">3</span>
                </a>
            </li>
        </ul>


        <div class="listview-title mt-2">Vista de Lista de Imágenes Completa</div>
        <ul class="listview image-listview">
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Sophie Silverton</div>
                        <span class="text-muted">Texto</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Frank Sjögren</div>
                        <span class="badge badge-primary">3</span>
                    </div>
                </div>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Imágenes Insertada</div>
        <ul class="listview image-listview inset">
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Sophie Silverton</div>
                        <span class="text-muted">Texto</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Frank Sjögren</div>
                        <span class="badge badge-primary">3</span>
                    </div>
                </div>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Imágenes Completa</div>
        <ul class="listview image-listview">
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Sophie Silverton</div>
                        <span class="text-muted">Texto</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Frank Sjögren</div>
                        <span class="badge badge-primary">3</span>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Imágenes Insertada</div>
        <ul class="listview image-listview inset">
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Sophie Silverton</div>
                        <span class="text-muted">Texto</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>Frank Sjögren</div>
                        <span class="badge badge-primary">3</span>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Iconos Completa</div>
        <ul class="listview image-listview">
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="wallet-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Billetera</div>
                        <span class="text-muted">$ 5,503.30</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Tarjetas</div>
                        <span class="badge badge-primary">2</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Depósito</div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Iconos Insertada</div>
        <ul class="listview image-listview inset">
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="wallet-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Billetera</div>
                        <span class="text-muted">$ 5,503.30</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Tarjetas</div>
                        <span class="badge badge-primary">2</span>
                    </div>
                </div>
            </li>
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Depósito</div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Iconos Completa</div>
        <ul class="listview image-listview">
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="wallet-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Billetera</div>
                        <span class="text-muted">$ 5,503.30</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Tarjetas</div>
                        <span class="badge badge-primary">2</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Depósito</div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Iconos Insertada</div>
        <ul class="listview image-listview inset">
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="wallet-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Billetera</div>
                        <span class="text-muted">$ 5,503.30</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="card-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Tarjetas</div>
                        <span class="badge badge-primary">2</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>Depósito</div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Con Encabezado y Pie de Página Completa</div>
        <ul class="listview image-listview">
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Usuario</header>
                            John Fonseca
                            <footer>Florida</footer>
                        </div>
                        <span class="text-muted">Editar</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Administrador</header>
                            Sophie Silverton
                            <footer>París</footer>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Usuario</header>
                            Frank Sjögren
                            <footer>Madrid</footer>
                        </div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Con Encabezado y Pie de Página Insertada</div>
        <ul class="listview image-listview inset">
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Usuario</header>
                            John Fonseca
                            <footer>Florida</footer>
                        </div>
                        <span class="text-muted">Editar</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Administrador</header>
                            Sophie Silverton
                            <footer>París</footer>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                    <div class="in">
                        <div>
                            <header>Usuario</header>
                            Frank Sjögren
                            <footer>Madrid</footer>
                        </div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Solo Texto Completa</div>
        <ul class="listview image-listview text">
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>Sophie Silverton</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>Frank Sjögren</div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Solo Texto Insertada</div>
        <ul class="listview image-listview text inset">
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>John Fonseca</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>Sophie Silverton</div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="in">
                        <div>Frank Sjögren</div>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Medios Completa</div>
        <ul class="listview image-listview media">
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Fotos
                            <div class="text-muted">subtexto</div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Vídeos
                            <div class="text-muted">subtexto</div>
                        </div>
                        <span class="badge badge-primary">53</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Álbumes
                            <div class="text-muted">subtexto</div>
                        </div>
                        <span class="text-muted">Texto</span>
                    </div>
                </a>
            </li>
        </ul>

        <div class="listview-title mt-2">Vista de Lista de Enlaces de Medios Insertada</div>
        <ul class="listview image-listview media inset mb-2">
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Fotos
                            <div class="text-muted">subtexto</div>
                        </div>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Vídeos
                            <div class="text-muted">subtexto</div>
                        </div>
                        <span class="badge badge-primary">53</span>
                    </div>
                </a>
            </li>
            <li>
                <a href="#" class="item">
                    <div class="imageWrapper">
                        <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="in">
                        <div>
                            Álbumes
                            <div class="text-muted">subtexto</div>
                        </div>
                        <span class="text-muted">Texto</span>
                    </div>
                </a>
            </li>
        </ul>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>