<template>
    <router-link tag="div" class="item" :to="'/crypto/token/' + record.address">
        <div class="icon-box text-success">
            <ion-icon name="trending-up-outline"></ion-icon>
        </div>
        <div class="in">
            <div>
                <strong>{{ record.name }}</strong>
                <div class="text-small text-secondary">{{ record.balance }} {{ record.symbol }}</div>
            </div>
            <div class="text-end">
                <strong>{{ $root.getCalcFromRate(record.balance, $root.getPairUser(record.address)) }} {{ $root.user.coin.symbol }}</strong>
                <!-- <div class="text-small">
                    <span class="badge badge-success">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                        2.59%
                    </span>
                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1"><ion-icon role="img" class="md hydrated" name="add-outline"></ion-icon> Enviar </button>
                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1"><ion-icon role="img" class="md" name="swap-horizontal-outline"></ion-icon> Swap </button>
                </div> -->
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        record: {
            type: Object,
            default: {
                address: '',
                name: '',
                symbol: '',
                decimals: '',
            }
        }
    }
}
</script>