<template>
    <router-view v-if="assets!==null&&user.coin!==null" :key="$route.fullPath"></router-view>
    <!-- <div class="spinner-border text-success" role="status"></div> -->
     <!-- {{assets}} -->
</template>

<script>
import {getAssets,getAsset, getPairs,getBalance} from '@/utils/web3';
import { ethers } from 'ethers';
import { mapGetters } from 'vuex';

export default {
    name: 'AppView',
    data() {
      return {
        loading: false,
        assets: null,
        assets_fast: null,
        site: {
          name: {
            full: 'Sombrero Wallet',
            regular: 'SombreroWallet',
          },
        },
        user: {
          token_default: process.env.VUE_APP_SOMBRERO_CONTRACT_DEFAULT_TOKEN,
          coin: null,
          balanceTxs: 0,
          wallet: {
            address: '0x0000000000000000000000000000000000000000',
            pkey: '0x0000000000000000000000000000000000000000000000000000000000000000',
          }
        }
      };
    },
    computed: {
      ...mapGetters({ 'WalletUser': 'WalletUser' }),
      totalCoinDefault() {
        let sum = 0;
        if (this.assets !== null && this.assets.length > 0) {
          this.assets.forEach(asset => {
            const valu = this.getCalcFromRate(asset.balance, this.getPairUser(asset.address))
            sum += valu
          });
        }
        return sum;
      },
      // 'WalletUser.wallet'() {
      //   return this.WalletUser ?? {address:'',pkey:''};
      // }
    },
    created() {
      const pkey = localStorage.getItem('pkey') // pasar al store
      console.log('pkey', pkey)
    },
    async mounted() {
      console.log('process.env.BASE_URL', process.env.BASE_URL)
      console.log('process.env.VUE_APP_RPC_SERVER', process.env.VUE_APP_RPC_SERVER)
      console.log('process.env.VUE_APP_SOMBRERO_CONTRACT_DEFAULT_TOKEN', process.env.VUE_APP_SOMBRERO_CONTRACT_DEFAULT_TOKEN)
      console.log('process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY', process.env.VUE_APP_SOMBRERO_CONTRACT_FACTORY)
      if (this.WalletUser) {
        this.user.wallet.address = this.WalletUser.address
        this.user.wallet.pkey = this.WalletUser.pkey
        const balance = await getBalance(this.WalletUser.address)
        console.log('balance', balance)
        this.user.balanceTxs = balance
      }

      let assets = []
      let assets_ = await this.fetchAssets(this.user.wallet.address)
      console.log('assets_', assets_)
      let assets_fast = {}
      if (assets_ !== null && assets_.length > 0) {
        assets_.forEach(asset => {
          asset.fast_pairs = {}
          asset.pairs.forEach(pair_ => {
            console.log('pair_', pair_.address)
            asset.fast_pairs[pair_.address] = pair_.rate
          });
          // if (!assets_fast[asset.address]) assets_fast[asset.address]
          assets_fast[asset.address] = asset.fast_pairs
          console.log('asset:mounted', asset)
          // const valu = this.getCalcFromRate(asset.balance, this.getPairUser(asset.address))
        });
      }
      this.assets = assets_
      console.log('assets:mounted', assets)
      this.assets_fast = assets_fast

      const user_coin = await this.fetchAsset(this.user.token_default, this.user.wallet.address)
      user_coin.pairs.forEach(a => {
      })
      this.user.coin = user_coin
    },
    updated() {
    },
    methods: {
      getBalanceToken(address_token) {
        let index = this.assets.findIndex(a => a.address == address_token)
        return this.assets[index].balance ?? 0;
      },
      getCalcFromRate(amount, rate) {
        const num = amount * rate
        return isNaN(num) ? 0 : (num);
      },
      getPairUser(address_token) {
          if (address_token == this.user.token_default) return 1;
          return this.assets_fast[address_token][this.user.token_default] ?? 0;
      },
      async fetchPairs(address_token) {
        try {
          return await getPairs(address_token);
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
      },
      async fetchAssets(wallet_address) {
        try {
          return await getAssets(wallet_address);
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
      },
      async fetchAsset(asset_addres, wallet_address) {
        try {
          return await getAsset(asset_addres, wallet_address);;
        } catch (error) {
            console.error('Error fetching assets:', error);
        }
      },
      generarStringAleatorio(longitud) {
          const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let resultado = '';
          const caracteresLength = caracteres.length;
          for (let i = 0; i < longitud; i++) {
              resultado += caracteres.charAt(Math.floor(Math.random() * caracteresLength));
          }
          return resultado;
      },
    },
}
</script>