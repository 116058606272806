<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Cuadrícula</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="section-title">Cuadrícula de Bootstrap</div>

            Estamos usando Bootstrap <a href="https://getbootstrap.com/docs/5.0/layout/grid/" target="_blank">sistema de
                cuadrícula</a>.
        </div>

        <div class="section mt-3">
            <div class="section-title">1 columna</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>

        <div class="section mt-3">
            <div class="section-title">2 columnas</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>


        <div class="section mt-3">
            <div class="section-title">3 columnas</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>

        <div class="section mt-3">
            <div class="section-title">4 columnas</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>

        <div class="section mt-3">
            <div class="section-title">5 columnas</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>

        <div class="section mt-3">
            <div class="section-title">6 columnas</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
                <div class="col">
                    <div class="exampleBox">.col</div>
                </div>
            </div>

        </div>

        <div class="section mt-2 mb-4">
            <div class="section-title">¿Necesitas más cuadrícula?</div>

            <div class="row">
                <div class="col">
                    <div class="exampleBox">Consulta <a href="https://getbootstrap.com/docs/5.0/layout/grid/"
                            target="_blank" class="ms-05">el sistema de cuadrícula de Bootstrap 5</a> </div>
                </div>
            </div>

        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>