<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Tipografía</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Encabezados</div>
            <div class="card">
                <div class="card-body">
                    <h1>Encabezado h1</h1>
                    <p class="mb-3">Ejemplo de texto</p>
                    <h2>Encabezado h2</h2>
                    <p class="mb-3">Ejemplo de texto</p>
                    <h3>Encabezado h3</h3>
                    <p class="mb-3">Ejemplo de texto</p>
                    <h4>Encabezado h4</h4>
                    <p class="mb-3">Ejemplo de texto</p>
                    <h5>Encabezado h5</h5>
                    <p class="mb-3">Ejemplo de texto</p>
                    <h6>Encabezado h6</h6>
                    <p class="mb-0">Ejemplo de texto</p>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Citas</div>
            <div class="card">
                <div class="card-body">
                    <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                            ante.</p>
                    </blockquote>

                    <blockquote class="blockquote">
                        <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a
                            ante.</p>
                        <footer class="blockquote-footer">Alguien famoso en <cite title="Source Title">Título de la
                                fuente</cite></footer>
                    </blockquote>
                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Listas</div>
            <div class="card">
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li>Lorem ipsum dolor sit amet</li>
                        <li>Consectetur adipiscing elit</li>
                        <li>Integer molestie lorem at massa</li>
                        <li>Facilisis in pretium nisl aliquet</li>
                        <li>Nulla volutpat aliquam velit
                            <ul>
                                <li>Phasellus iaculis neque</li>
                                <li>Purus sodales ultricies</li>
                                <li>Vestibulum laoreet porttitor sem</li>
                                <li>Ac tristique libero volutpat at</li>
                            </ul>
                        </li>
                        <li>Faucibus porta lacus fringilla vel</li>
                        <li>Aenean sit amet erat nunc</li>
                        <li>Eget porttitor lorem</li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Principal</div>
            <div class="card">
                <div class="card-body">
                    <code>.lead</code>
                    <div class="lead">
                        Este es un texto de ejemplo.
                    </div>
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Elementos de texto</div>
            <div class="card">
                <div class="card-body">
                    <div class="lead">
                        <p>Puedes usar la etiqueta mark para <mark>resaltar</mark> texto.</p>
                        <p><del>Esta línea de texto debe tratarse como texto eliminado.</del></p>
                        <p><s>Esta línea de texto debe tratarse como ya no precisa.</s></p>
                        <p><ins>Esta línea de texto debe tratarse como una adición al documento.</ins></p>
                        <p><u>Esta línea de texto se mostrará como subrayada</u></p>
                        <p><small>Esta línea de texto debe tratarse como letra pequeña.</small></p>
                        <p><strong>Esta línea se muestra como texto en negrita.</strong></p>
                        <p><em>Esta línea se muestra como texto en cursiva.</em></p>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>