<template>
    <!-- Encabezado de la aplicación -->
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <a href="/auth/login" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-2 text-center">
            <h1>Importar PKey</h1>
            <h4>Para iniciar, vamos a ingresar tu <strong>Llave privada</strong></h4>
            <p>Es importante que uses y/o mantengas esta clave en un dispositivo seguro y en un lugar confibale, ya que tiene el control completo de tus transacciones.</p>
        </div>
        <div class="section mb-5 p-2">
            <form @submit.prevent="submitForm">
                <div class="form-group basic">
                    <input type="password" class="form-control verification-input" placeholder="••••" maxlength="66" v-model="form.pin" required style="width: 70% !important">
                </div>
                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar</button>
                </div>
            </form>
        </div>

    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Dialog Form -->
    <div class="modal fade dialogbox" id="DialogForm" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Verificar importacion</h5>
                </div>
                <form @submit.prevent="submitFormCreate">
                    <div class="modal-body text-start mb-2">

                        <!-- <div class="form-group basic">
                            <div class="input-wrapper">
                                <div class="form-group basic">
                                    <input type="password" class="form-control verification-input" placeholder="••••" maxlength="4" v-model="form.verify">
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <button type="button" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</button>
                            <button type="submit" class="btn btn-text-primary">CONFIRMAR</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- * Dialog Form -->
</template>

<script>
import store from '@/store';
import { generateMnemonic, createWalletFromSeedWithPassword, verifyPrivateKeyAndGetAddress, getWalletFromPkey } from '@/utils/web3';
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            form: {
                pin: '0xe73f2dd207a58c7ff025aa07ac2dff426ffa811557889b0c7f5928cebf78133d',
                verify: '',
            },
            wallet: null
        };
    },
    methods: {
        submitForm() {
            console.log('submitForm', this.form.pin)
            let pkey = '';
            if (String(this.form.pin).length == 64) pkey = "0x" + String(this.form.pin)
            if (String(this.form.pin).length == 65) pkey = "0" + String(this.form.pin)
            if (String(this.form.pin).length == 66) pkey = String(this.form.pin)

            if (String(this.form.pin).length == 66) {
                const result = verifyPrivateKeyAndGetAddress(this.form.pin)
                console.log('result', result)
                if (result.success) {
                    var DialogForm = new Modal(document.getElementById('DialogForm'), {
                        keyboard: false
                    })
                    DialogForm.show()
                }
            }
        },
        async submitFormCreate() {
            console.log('submitFormCreate')
            
            const result = verifyPrivateKeyAndGetAddress(this.form.pin)
            if (result.success) {
                console.log('result', result)
                localStorage.setItem('pkey', this.form.pin)
                await store.dispatch('loadWallet')
                this.$router.push('/');
                location.reload()
            }
            return;
        }
    }
}
</script>