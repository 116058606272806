<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Toasts</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2 mb-2">
            <div class="section-title">Uso</div>
            <div class="card">
                <div class="card-body">
                    <h4>Uso básico</h4>
                    Activarlo con;
                    <p>
                        <code>toastbox('toastID')</code>
                    </p>
                    <h4>Cierre automático</h4>
                    También puedes cerrarlo automáticamente después de algunos segundos después de llamarlo.
                    <br>
                    <strong>Ejemplo : </strong>
                    Cierre automático en 3 segundos.<br>
                    <code>toastbox('toastID', 3000)</code>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="section-title">Superior</div>
        </div>
        <ul class="listview link-listview inset">
            <li>
                <a href="#" @click="toastbox('toast-1')">
                    Predeterminado
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-2')">
                    Tocar para cerrar
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-3')">
                    Con icono
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-4', 2000)">
                    Cierre automático
                </a>
            </li>
        </ul>

        <!-- toast superior -->
        <div id="toast-1" class="toast-box toast-top">
            <div class="in">
                <div class="text">
                    Se recibió un nuevo pago
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast superior -->

        <!-- toast superior tocar para cerrar -->
        <div id="toast-2" class="toast-box toast-top tap-to-close">
            <div class="in">
                <div class="text">
                    Tocar para cerrarme
                </div>
            </div>
        </div>
        <!-- * toast superior tocar para cerrar -->

        <!-- toast superior con icono -->
        <div id="toast-3" class="toast-box toast-top">
            <div class="in">
                <ion-icon name="cash-outline" class="text-success"></ion-icon>
                <div class="text">
                    ¡El dinero se ha enviado!
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-success close-button">CERRAR</button>
        </div>
        <!-- * toast superior con icono -->

        <!-- toast superior cierre automático en 2 segundos -->
        <div id="toast-4" class="toast-box toast-top">
            <div class="in">
                <div class="text">
                    Cierre automático en 2 segundos.
                </div>
            </div>
        </div>
        <!-- * toast superior cierre automático en 2 segundos -->


        <div class="section mt-2">
            <div class="section-title">Inferior</div>
        </div>
        <ul class="listview link-listview inset">
            <li>
                <a href="#" @click="toastbox('toast-5')">
                    Predeterminado
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-6')">
                    Tocar para cerrar
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-7')">
                    Con icono
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-8', 2000)">
                    Cierre automático
                </a>
            </li>
        </ul>

        <!-- toast inferior -->
        <div id="toast-5" class="toast-box toast-bottom">
            <div class="in">
                <div class="text">
                    Se recibió un nuevo pago
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast inferior -->

        <!-- toast inferior tocar para cerrar -->
        <div id="toast-6" class="toast-box toast-bottom tap-to-close">
            <div class="in">
                <div class="text">
                    Tocar para cerrarme.
                </div>
            </div>
        </div>
        <!-- * toast inferior tocar para cerrar -->

        <!-- toast inferior con icono -->
        <div id="toast-7" class="toast-box toast-bottom">
            <div class="in">
                <ion-icon name="card-outline" class="text-danger"></ion-icon>
                <div class="text">
                    Se ha eliminado tu tarjeta.
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast inferior con icono -->

        <!-- toast superior cierre automático en 2 segundos -->
        <div id="toast-8" class="toast-box toast-bottom">
            <div class="in">
                <div class="text">
                    Cierre automático en 2 segundos.
                </div>
            </div>
        </div>
        <!-- * toast superior cierre automático en 2 segundos -->




        <div class="section mt-2">
            <div class="section-title">Centro</div>
        </div>
        <ul class="listview link-listview inset">
            <li>
                <a href="#" @click="toastbox('toast-9')">
                    Predeterminado
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-10')">
                    Tocar para cerrar
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-11')">
                    Con icono
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-12', 2000)">
                    Cierre automático
                </a>
            </li>
        </ul>

        <!-- toast centro -->
        <div id="toast-9" class="toast-box toast-center">
            <div class="in">
                <div class="text">
                    Toast centrado
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>
        <!-- toast centro -->

        <!-- toast centro tocar para cerrar -->
        <div id="toast-10" class="toast-box toast-center tap-to-close">
            <div class="in">
                <div class="text">
                    Tocar para cerrarme
                </div>
            </div>
        </div>
        <!-- toast centro tocar para cerrar -->

        <!-- toast centro con icono -->
        <div id="toast-11" class="toast-box toast-center">
            <div class="in">
                <ion-icon name="checkmark-circle" class="text-success"></ion-icon>
                <div class="text">
                    Mensaje de éxito
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">CERRAR</button>
        </div>
        <!-- toast centro con icono -->

        <!-- toast centro cierre automático en 2 segundos -->
        <div id="toast-12" class="toast-box toast-center">
            <div class="in">
                <div class="text">
                    Cierre automático en 2 segundos
                </div>
            </div>
        </div>
        <!-- toast centro cierre automático en 2 segundos -->




        <div class="section mt-2">
            <div class="section-title">Colores</div>
        </div>
        <ul class="listview link-listview inset mb-2">
            <li>
                <a href="#" @click="toastbox('toast-13')">
                    <span class="text-primary">Primario</span>
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-14')">
                    <span class="text-secondary">Secundario</span>
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-15')">
                    <span class="text-success">Éxito</span>
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-16')">
                    <span class="text-danger">Peligro</span>
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-17')">
                    <span class="text-warning">Advertencia</span>
                </a>
            </li>
            <li>
                <a href="#" @click="toastbox('toast-18')">
                    <span class="text-info">Información</span>
                </a>
            </li>
        </ul>

        <!-- toast primario -->
        <div id="toast-13" class="toast-box toast-bottom bg-primary">
            <div class="in">
                <div class="text">
                    Color primario
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast primario -->

        <!-- toast secundario -->
        <div id="toast-14" class="toast-box toast-bottom bg-secondary">
            <div class="in">
                <div class="text">
                    Color secundario
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast secundario -->

        <!-- toast éxito -->
        <div id="toast-15" class="toast-box toast-bottom bg-success">
            <div class="in">
                <div class="text">
                    Color de éxito
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast éxito -->

        <!-- toast peligro -->
        <div id="toast-16" class="toast-box toast-bottom bg-danger">
            <div class="in">
                <div class="text">
                    Color de peligro
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast peligro -->

        <!-- toast advertencia -->
        <div id="toast-17" class="toast-box toast-bottom bg-warning">
            <div class="in">
                <div class="text">
                    Color de advertencia
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast advertencia -->

        <!-- toast información -->
        <div id="toast-18" class="toast-box toast-bottom bg-info">
            <div class="in">
                <div class="text">
                    Color de información
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-text-light close-button">OK</button>
        </div>
        <!-- * toast información -->




    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->
</template>

<script>
export default {
    mounted() {
        this.runScripts()
    },
    methods: {
        runScripts() {
            let self = this
            var toastCloseButton = document.querySelectorAll(".toast-box .close-button");
            var toastTaptoClose = document.querySelectorAll(".toast-box.tap-to-close");
            var toastBoxes = document.querySelectorAll(".toast-box");
            toastCloseButton.forEach(function (el) {
                el.addEventListener("click", function (e) {
                    e.preventDefault();
                    self.closeToastBox();
                })
            })
            // tap to close toast
            toastTaptoClose.forEach(function (el) {
                el.addEventListener("click", function (e) {
                    self.closeToastBox();
                })
            })
        },
        closeToastBox() {
            var toastBoxes = document.querySelectorAll(".toast-box");
            toastBoxes.forEach(function (el) {
                el.classList.remove("show")
            })
        },
        toastbox(target, time) {
            let self = this
            var a = document.getElementById(target);
            self.closeToastBox()
            setTimeout(() => {
                a.classList.add("show")
            }, 100);
            if (time) {
                time = time + 100;
                setTimeout(() => {
                    self.closeToastBox()
                }, time);
            }
        }
    },
}
</script>