<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader no-border transparent">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Bienvenido</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <!-- deslizador de carrusel -->
        <div class="carousel-slider splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <li class="splide__slide p-2">
                        <img src="@/assets/img/sample/photo/vector1.png" alt="alt" class="imaged w-100 square mb-4">
                        <h2>Fácil de usar</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                    <li class="splide__slide p-2">
                        <img src="@/assets/img/sample/photo/vector2.png" alt="alt" class="imaged w-100 square mb-4">
                        <h2>Minimalista y elegante</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                    <li class="splide__slide p-2">
                        <img src="@/assets/img/sample/photo/vector3.png" alt="alt" class="imaged w-100 square mb-4">
                        <h2>Componentes fáciles de usar</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </li>
                </ul>
            </div>
        </div>
        <!-- * deslizador de carrusel -->

        <div class="carousel-button-footer">
            <div class="row">
                <div class="col-6">
                    <a href="app-components.html" class="btn btn-outline-secondary btn-lg btn-block">Registrarse</a>
                </div>
                <div class="col-6">
                    <a href="app-components.html" class="btn btn-primary btn-lg btn-block">Iniciar sesión</a>
                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la aplicación -->

</template>



<script>
export default {
    mounted() {
        this.runScripts()
    },
    methods: {
        runScripts() {
            //-----------------------------------------------------------------------
            // Carousel
            // Splide Carousel
            document.addEventListener('DOMContentLoaded', function () {

                // Slider Carousel
                document.querySelectorAll('.carousel-slider').forEach(carousel => new Splide(carousel, {
                    perPage: 1,
                    rewind: false,
                    type: "loop",
                    gap: 16,
                    padding: 16,
                    arrows: false,
                    pagination: true
                }).mount());

            });
            //-----------------------------------------------------------------------

        }
    }
}
</script>