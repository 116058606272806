<template>
    <div class="section full gradientSection">
        <div class="in">
            <h5 class="title mb-2">Saldo actual</h5>
            <h1 class="total">$ {{ $root.totalCoinDefault }}</h1>
            <h4 class="caption">
                <span class="iconbox text-success">
                    <ion-icon name="trending-up-outline"></ion-icon>
                </span>
                {{ $root.user.balanceTxs }} CTN ({{ gas_price }}) <br />
                {{ $root.user.balanceTxs / gas_price }}
            </h4>
            <div class="wallet-inline-button mt-5">
                <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#DialogImaged">
                    <div class="iconbox">
                        <ion-icon name="qr-code"></ion-icon>
                    </div>
                    <strong>Recibir</strong>
                </a>
                <!-- <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                    <div class="iconbox">
                        <ion-icon name="arrow-up-outline"></ion-icon>
                    </div>
                    <strong>Depósito</strong>
                </a> -->
                <!-- <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                    <div class="iconbox">
                        <ion-icon name="arrow-down-outline"></ion-icon>
                    </div>
                    <strong>Retirada</strong>
                </a> -->
                <router-link to="/send" class="item">
                    <div class="iconbox">
                        <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <strong>Enviar</strong>
                </router-link>
                <router-link to="/swap" class="item">
                    <div class="iconbox">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                    <strong>Intercambio</strong>
                </router-link>
                <router-link to="/p2p-exchange" class="item">
                    <div class="iconbox">
                        <ion-icon name="swap-vertical-outline"></ion-icon>
                    </div>
                    <strong>P2P</strong>
                </router-link>
            </div>
            <!-- {{ $root.user.coin }} -->
        </div>
    </div>
        <!-- Hoja de acción de depósito -->
        <div class="modal fade action-sheet" id="depositActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Depósito</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form>
                                <div class="form-group basic mb-2">
                                    <div class="input-wrapper">
                                        <label class="label" for="account2">Seleccionar cuenta</label>
                                        <select class="form-control custom-select" id="account2">
                                            <option value="0">Ahorros (*** 5019)</option>
                                            <option value="1">Inversión (*** 6212)</option>
                                            <option value="2">Hipoteca (*** 5021)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="depositAmount">Ingrese la cantidad</label>
                                        <div class="exchange-group small">
                                            <div class="input-col">
                                                <input type="text" class="form-control form-control-lg pe-0"
                                                    id="depositAmount" placeholder="0" value="380" maxlength="14">
                                            </div>
                                            <div class="select-col">
                                                <select class="form-select form-select-lg currency">
                                                    <option value="USD" selected>USD</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="AUD">AUD</option>
                                                    <option value="CAD">CAD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2">
                                    <button type="button" class="btn btn-primary btn-lg btn-block"
                                        data-bs-dismiss="modal">Depositar</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción de depósito-->

        <!-- Hoja de acción de retiro -->
        <div class="modal fade action-sheet" id="withdrawActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Retirada</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <form>
                                <div class="form-group basic mb-2">
                                    <div class="input-wrapper">
                                        <label class="label" for="account3">Seleccionar cuenta</label>
                                        <select class="form-control custom-select" id="account3">
                                            <option value="0">Ahorros (*** 5019)</option>
                                            <option value="1">Inversión (*** 6212)</option>
                                            <option value="2">Hipoteca (*** 5021)</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group basic mb-2">
                                    <div class="input-wrapper">
                                        <label class="label" for="iban1">IBAN</label>
                                        <input type="email" class="form-control" id="iban1"
                                            placeholder="Ingrese su IBAN">
                                        <i class="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                    </div>
                                </div>

                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="withdrawAmount">Ingrese la cantidad</label>
                                        <div class="exchange-group small">
                                            <div class="input-col">
                                                <input type="text" class="form-control form-control-lg pe-0"
                                                    id="withdrawAmount" placeholder="0" value="380" maxlength="14">
                                            </div>
                                            <div class="select-col">
                                                <select class="form-select form-select-lg currency">
                                                    <option value="USD" selected>USD</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="AUD">AUD</option>
                                                    <option value="CAD">CAD</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-2">
                                    <button type="button" class="btn btn-primary btn-lg btn-block"
                                        data-bs-dismiss="modal">Retirar</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción de retiro-->
        <!-- Hoja de acción de envío -->
        <div class="modal fade action-sheet" id="sendActionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Enviar</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <!-- <form-send /> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción de envío-->
        <!-- <div>
            <h2>Último Bloque</h2>
            <button @click="fetchLatestBlock">Obtener Último Bloque</button>
            <p v-if="latestBlock">{{ latestBlock }}</p>
        </div> -->
        
        <div class="modal fade dialogbox" id="DialogImaged" data-bs-backdrop="static" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <qr-code-address :address="$root.user.wallet.address" />
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <!-- <a href="#" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</a> -->
                            <a href="#" class="btn btn-text-primary" data-bs-dismiss="modal">HECHO</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
</template>

<script>
import { getGasPrice, getLatestBlock } from '@/utils/web3';

export default {
    data() {
        return {
            latestBlock: null,
            gas_price: 9e18,
        };
    },
    async mounted() {
        console.log('this.$root.user.wallet.address', this.$root.user.wallet.address)
        const gas_price = await getGasPrice()
        console.log('gas_price', gas_price)
        // this.gas_price = gas_price / 1e18
        console.log('balance2', this.$root.user.balanceTxs)
    },
    methods: {
        async fetchLatestBlock () {
            try {
                const block = await getLatestBlock();
                console.log('block', block)
                this.latestBlock = JSON.stringify(block);
            } catch (error) {
                console.error('Error fetching latest block:', error);
            }
        }
    }
}
</script>

<style scoped>
button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
