<template>
    <div class="accordion-item">
        <h2 :class="elementoBg">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + elementoId">
                <ion-icon v-if="icon !== null" :name="icon"></ion-icon>
                {{ title }}
            </button>
        </h2>
        <div :id="elementoId" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
            <div class="accordion-body">
                <div v-if="html" v-html="html"></div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        keyId: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: 'Sin titulo'
        },
        html: {
            type: String,
            default: '<b>Sin contenido HTML</b>'
        },
        icon: {
            type: String,
            default: null
        },
        bg: {
            type: String,
            default: null
        },
    },
    computed: {
        elementoId() {
            // Si el prop `id` existe, lo usa; si no, genera un string usando la función en $root
            return this.id || this.$root.generarStringAleatorio(10);
        },
        elementoBg() {
            // Si el prop `id` existe, lo usa; si no, genera un string usando la función en $root
            return 'accordion-header bg-' + this.bg || 'accordion-header';
        },
    },
}
</script>