<template>
    
    <!-- Encabezado de la aplicación -->
    <!-- <div class="appHeader no-border">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">
            Bienvenid@
        </div>
        <div class="right">
        </div>
    </div> -->
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section">
            <div class="splash-page mt-5 mb-5">
                <h2 class="mb-2">¡Bienvenido de nuevo!</h2>
                <div class="mb-3"><img src="@/assets/img/app/crypto-wallet-8073227-6480629.webp" alt="image" class="imaged w-50 square"></div>
                <p>
                    Somos <strong>{{ $root.site.name.full }}n un ecosisitema descentralizado que te entrega seguridad en tus cripto activos.</strong>
                </p>
            </div>
        </div>


        <div class="fixed-bar">
            <div class="row">
                <div class="col-6">
                    <a href="/auth/create-wallet" class="btn btn-lg btn-outline-secondary btn-block">Crear</a>
                </div>
                <div class="col-6">
                    <a href="/auth/login" class="btn btn-lg btn-primary btn-block">Usar mi cuenta</a>
                </div>
            </div>
        </div>

    </div>
    <!-- * Cápsula de la aplicación -->

</template>