<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <router-link href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">
            Componentes
        </div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">

        <div class="section mt-2">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Componentes Reutilizables</h5>
                    <p class="card-text">
                        SombreroWallet viene con muchos componentes que puedes necesitar para tu proyecto. Puedes usarlos una y otra
                        vez.
                    </p>
                </div>
            </div>
        </div>

        <div class="listview-title mt-2">Tema</div>
        <ul class="listview image-listview inset mb-2">
            <li>
                <div class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="moon-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Modo Oscuro
                        <div class="form-check form-switch  ms-2">
                            <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch">
                            <label class="form-check-label" for="darkmodeSwitch"></label>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <div class="listview-title mt-1">Componentes</div>
        <ul class="listview image-listview inset mb-3">
            <li>
                <router-link to="/components/accordion" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Acordeón
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/action-sheet" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Hoja de Acción
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/add-to-home" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Agregar a Inicio
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/alert" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Alerta
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/appbottommenu" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Menú Inferior de la Aplicación
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/appheader" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Encabezado de la Aplicación
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/badge" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Insignias
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/button" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Botones
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/card" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Tarjetas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/carousel" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Carrusel - Control deslizante
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/charts" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Gráficos
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/checkbox" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Casilla de verificación
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/chips" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Fichas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/contentbox" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Cajas de contenido
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/dialog" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Diálogo
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/dropdown" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Desplegable
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/fullpage-slider" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Control Deslizante de Página Completa
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/grid" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Cuadrícula
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/icons" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Iconos
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/images" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Imágenes
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/inputs" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Entradas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/listview" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Vista de Lista
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/messages" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Mensajes
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/modal-and-panels" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Modal y Paneles
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/notification" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Notificación
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/preloader" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Precargador
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/progressbar" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Barra de progreso
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/radio" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Radio
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/search" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Búsqueda
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/table" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Tabla
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/tabs" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Pestañas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/timeline" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Cronología
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/toast" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Tostadas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/toggle" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Conmutador
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/tooltips" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Consejos de herramientas
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/components/typography" class="item">
                    <div class="icon-box bg-primary">
                        <ion-icon name="cube-outline"></ion-icon>
                    </div>
                    <div class="in">
                        Tipografía
                    </div>
                </router-link>
            </li>
        </ul>

    </div>
    <!-- * Cápsula de la Aplicación -->


    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <router-link href="index" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </router-link>
        <router-link href="app-pages" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </router-link>
        <router-link href="app-components" class="item active">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </router-link>
        <router-link href="app-cards" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </router-link>
        <router-link href="app-settings" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </router-link>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>

<script>
export default {
    mounted() {
        var pageBody = document.querySelector("body");
        //-----------------------------------------------------------------------
        // Dark Mode
        var checkDarkModeStatus = localStorage.getItem("FinappDarkmode");
        var switchDarkMode = document.querySelectorAll(".dark-mode-switch");
        var pageBodyActive = pageBody.classList.contains("dark-mode");

        // Check if enable as default
        // if (Finapp.Dark_Mode.default) {
            // pageBody.classList.add("dark-mode");
        // }

        // Local Dark Mode
        // if (Finapp.Dark_Mode.local_mode.enable) {
            // var nightStart = Finapp.Dark_Mode.local_mode.start_time;
            var nightStart = 20;
            // var nightEnd = Finapp.Dark_Mode.local_mode.end_time;
            var nightEnd = 7;
            var currentDate = new Date();
            var currentHour = currentDate.getHours();
            if (currentHour >= nightStart || currentHour < nightEnd) {
                // It is night time
                pageBody.classList.add("dark-mode");
            }
        // }

        // Auto Detect Dark Mode
        // if (Finapp.Dark_Mode.auto_detect.enable)
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                pageBody.classList.add("dark-mode");
            }

        function switchDarkModeCheck(value) {
            switchDarkMode.forEach(function (el) {
                el.checked = value
            })
        }
        // if dark mode on
        if (checkDarkModeStatus === 1 || checkDarkModeStatus === "1" || pageBody.classList.contains('dark-mode')) {
            switchDarkModeCheck(true);
            if (pageBodyActive) {
                // dark mode already activated
            }
            else {
                pageBody.classList.add("dark-mode")
            }
        }
        else {
            switchDarkModeCheck(false);
        }
        switchDarkMode.forEach(function (el) {
            el.addEventListener("click", function () {
                var darkmodeCheck = localStorage.getItem("FinappDarkmode");
                var bodyCheck = pageBody.classList.contains('dark-mode');
                if (darkmodeCheck === 1 || darkmodeCheck === "1" || bodyCheck) {
                    pageBody.classList.remove("dark-mode");
                    localStorage.setItem("FinappDarkmode", "0");
                    switchDarkModeCheck(false);
                }
                else {
                    pageBody.classList.add("dark-mode")
                    switchDarkModeCheck(true);
                    localStorage.setItem("FinappDarkmode", "1");
                }
            })
        })
        //-----------------------------------------------------------------------

    }
}
</script>