<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Iconos</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">


        <div class="section mt-2">
            <div class="section-title">Ionicons v5</div>
            <div class="card">
                <div class="card-body">
                    <p>
                        Estamos usando <a href="https://ionic.io/ionicons" target="_blank" rel="nofollow">Ionicons v5</a>.
                        Iconos de diseño premium para usar en Web, iOS, Android y Escritorio.
                    </p>
                    <h4 class="mb-2">Ejemplos</h4>
                    <div class="row text-center text-large iconExample">
                        <div class="col-2 mb-2">
                            <ion-icon name="wallet-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="cash-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="card-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="pricetag-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="chatbubbles-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="add-circle-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="earth-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="easel-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="fitness-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="flash-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="lock-open-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="mail-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="mic-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="podium-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="search-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="settings-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="speedometer-outline"></ion-icon>
                        </div>
                        <div class="col-2 mb-2">
                            <ion-icon name="trending-up-outline"></ion-icon>
                        </div>
                    </div>
                    <a href="https://ionic.io/ionicons" target="_blank" class="btn btn-secondary btn-block mt-1">
                        Mostrar Todos
                    </a>
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-4">
            <div class="section-title">Ionicons v4</div>
            <div class="card">
                <div class="card-body">
                    <p>
                        SombreroWallet también es compatible con <a href="https://ionic.io/ionicons/v4" target="_blank" rel="nofollow">Iconicons
                            v4</a>.
                    </p>
                    <h4 class="mb-2">Estilo Material</h4>
                    <div class="row text-center text-large iconExample">
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-add-circle"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-card"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-wallet"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-cash"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-alarm"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-settings"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-paper"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-mail"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-contacts"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-lock"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-tv"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-md-switch"></i>
                        </div>
                    </div>

                    <h4 class="mt-2 mb-2">Estilo iOS</h4>
                    <div class="row text-center text-large iconExample">
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-add-circle"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-card"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-wallet"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-cash"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-alarm"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-settings"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-paper"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-mail"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-contacts"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-lock"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-tv"></i>
                        </div>
                        <div class="col-2 mb-2">
                            <i class="icon ion-ios-switch"></i>
                        </div>
                    </div>
                    <a href="https://ionic.io/ionicons/v4" target="_blank" class="btn btn-secondary btn-block mt-1">
                        Mostrar Todos
                    </a>
                </div>
            </div>
        </div>

    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->
</template>