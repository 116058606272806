<template>
    

    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Imágenes</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule">


        <div class="section mt-2 mb-2">
            <div class="section-title">Comenzar a usar</div>
            <div class="card">
                <div class="card-body">
                    Agrega <code>.imaged</code> a la etiqueta img, y comienza a usar las utilidades de imagen.
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Radio de borde</div>
            <div class="card">
                <div class="card-body">
                    <p>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w48">
                        predeterminado
                    </p>
                    <p>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w48 rounded">
                        <code>.rounded</code>
                    </p>
                    <p class="mb-0">
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w48 square">
                        <code>.square</code>
                    </p>
                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Tamaños</div>
            <div class="card">
                <div class="card-body">
                    <div class="mb-1">
                        <div><code>.w16</code> 16px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w16">
                    </div>
                    <div class="mb-1">
                        <div><code>.w24</code> 24px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w24">
                    </div>
                    <div class="mb-1">
                        <div><code>.w36</code> 36px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w36">
                    </div>
                    <div class="mb-1">
                        <div><code>.w48</code> 48px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w48">
                    </div>
                    <div class="mb-1">
                        <div><code>.w64</code> 64px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                    </div>
                    <div class="mb-1">
                        <div><code>.w76</code> 76px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w76">
                    </div>
                    <div class="mb-1">
                        <div><code>.w86</code> 86px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w86">
                    </div>
                    <div class="mb-1">
                        <div><code>.w100</code> 100px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w100">
                    </div>
                    <div class="mb-1">
                        <div><code>.w120</code> 120px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w120">
                    </div>
                    <div class="mb-1">
                        <div><code>.w140</code> 140px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w140">
                    </div>
                    <div class="mb-1">
                        <div><code>.w160</code> 160px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w160">
                    </div>
                    <div class="mb-1">
                        <div><code>.w180</code> 180px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w180">
                    </div>
                    <div class="mb-1">
                        <div><code>.w200</code> 200px</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w200">
                    </div>
                    <div class="mb-1">
                        <div><code>.img-fluid</code> 100% de ancho</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged img-fluid">
                    </div>
                    <div class="mb-1">
                        <div><code>.w-75</code> 75% de ancho</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-75">
                    </div>
                    <div class="mb-1">
                        <div><code>.w-50</code> 50% de ancho</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-50">
                    </div>
                    <div class="mb-1">
                        <div><code>.w-25</code> 25% de ancho</div>
                        <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w-25">
                    </div>
                </div>
            </div>
        </div>


    </div>
    <!-- * Cápsula de la Aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

    <!-- Barra Lateral de la Aplicación -->
    <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-0">
                    <!-- caja de perfil -->
                    <div class="profileBox pt-2 pb-2">
                        <div class="image-wrapper">
                            <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged  w36">
                        </div>
                        <div class="in">
                            <strong>Sebastian Doe</strong>
                            <div class="text-muted">4029209</div>
                        </div>
                        <a href="#" class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                            <ion-icon name="close-outline"></ion-icon>
                        </a>
                    </div>
                    <!-- * caja de perfil -->
                    <!-- saldo -->
                    <div class="sidebar-balance">
                        <div class="listview-title">Saldo</div>
                        <div class="in">
                            <h1 class="amount">$ 2,562.50</h1>
                        </div>
                    </div>
                    <!-- * saldo -->

                    <!-- grupo de acciones -->
                    <div class="action-group">
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="add-outline"></ion-icon>
                                </div>
                                Depósito
                            </div>
                        </a>
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-down-outline"></ion-icon>
                                </div>
                                Retirada
                            </div>
                        </a>
                        <a href="index.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="arrow-forward-outline"></ion-icon>
                                </div>
                                Enviar
                            </div>
                        </a>
                        <a href="app-cards.html" class="action-button">
                            <div class="in">
                                <div class="iconbox">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                Mis Tarjetas
                            </div>
                        </a>
                    </div>
                    <!-- * grupo de acciones -->

                    <!-- menú -->
                    <div class="listview-title mt-1">Menú</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="index.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="pie-chart-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Resumen
                                    <span class="badge badge-primary">10</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-pages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="document-text-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Páginas
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-components.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="apps-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Componentes
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-cards.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="card-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Mis Tarjetas
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * menú -->

                    <!-- otros -->
                    <div class="listview-title mt-1">Otros</div>
                    <ul class="listview flush transparent no-line image-listview">
                        <li>
                            <a href="app-settings.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="settings-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Ajustes
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="component-messages.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="chatbubble-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Soporte
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="app-login.html" class="item">
                                <div class="icon-box bg-primary">
                                    <ion-icon name="log-out-outline"></ion-icon>
                                </div>
                                <div class="in">
                                    Cerrar sesión
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * otros -->

                    <!-- enviar dinero -->
                    <div class="listview-title mt-1">Enviar Dinero</div>
                    <ul class="listview image-listview flush transparent no-line">
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Artem Sazonov</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Sophie Asveld</div>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="item">
                                <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="image">
                                <div class="in">
                                    <div>Kobus van de Vegte</div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <!-- * enviar dinero -->

                </div>
            </div>
        </div>
    </div>
    <!-- * Barra Lateral de la Aplicación -->

</template>