<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Fichas</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="full-height">

        <div class="section mt-1">
            <div class="section-title">Predeterminado</div>
            <div class="card">
                <div class="card-body">

                    <div class="chip">
                        <span class="chip-label">Predeterminado</span>
                    </div>

                    <div class="chip chip-outline">
                        <span class="chip-label">Con contorno</span>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">con Icono</div>
            <div class="card">
                <div class="card-body">


                    <div class="chip chip-media">
                        <i class="chip-icon bg-primary">
                            <ion-icon name="person"></ion-icon>
                        </i>
                        <span class="chip-label">523</span>
                    </div>

                    <div class="chip chip-media">
                        <i class="chip-icon bg-danger">
                            <ion-icon name="location"></ion-icon>
                        </i>
                        <span class="chip-label">Londres</span>
                    </div>

                    <div class="chip chip-media">
                        <i class="chip-icon bg-warning">
                            <ion-icon name="chatbubble"></ion-icon>
                        </i>
                        <span class="chip-label">Comentario</span>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">con Imagen</div>
            <div class="card">
                <div class="card-body">

                    <div class="chip chip-media">
                        <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="avatar">
                        <span class="chip-label">John</span>
                    </div>

                    <div class="chip chip-media">
                        <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="avatar">
                        <span class="chip-label">Oliver</span>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Botón de Acción</div>
            <div class="card">
                <div class="card-body">

                    <div class="chip chip-media">
                        <img src="@/assets/img/sample/avatar/avatar1.jpg" alt="avatar">
                        <span class="chip-label">John</span>
                        <a href="#" class="chip-delete">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>

                    <div class="chip chip-media">
                        <img src="@/assets/img/sample/avatar/avatar2.jpg" alt="avatar">
                        <span class="chip-label">Oliver</span>
                        <a href="#" class="chip-delete">
                            <ion-icon name="star"></ion-icon>
                        </a>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2 mb-2">
            <div class="section-title">Colores</div>
            <div class="card">
                <div class="card-body">

                    <div class="chip chip-primary ms-05 mb-05">
                        <span class="chip-label">Primario</span>
                    </div>

                    <div class="chip chip-danger ms-05 mb-05">
                        <span class="chip-label">Peligro</span>
                    </div>

                    <div class="chip chip-success ms-05 mb-05">
                        <span class="chip-label">Éxito</span>
                    </div>

                    <div class="chip chip-warning ms-05 mb-05">
                        <span class="chip-label">Alerta</span>
                    </div>

                    <div class="chip chip-info ms-05 mb-05">
                        <span class="chip-label">Información</span>
                    </div>

                    <div class="chip chip-light ms-05 mb-05">
                        <span class="chip-label">Claro</span>
                    </div>

                    <div class="chip chip-dark ms-05 mb-05">
                        <span class="chip-label">Oscuro</span>
                    </div>


                    <div class="chip chip-outline chip-primary ms-05 mb-05">
                        <span class="chip-label">Primario</span>
                    </div>

                    <div class="chip chip-outline chip-success ms-05 mb-05">
                        <span class="chip-label">Éxito</span>
                    </div>

                    <div class="chip chip-outline chip-warning ms-05 mb-05">
                        <span class="chip-label">Alerta</span>
                    </div>

                    <div class="chip chip-outline chip-danger ms-05 mb-05">
                        <span class="chip-label">Peligro</span>
                    </div>

                    <div class="chip chip-outline chip-info ms-05 mb-05">
                        <span class="chip-label">Información</span>
                    </div>

                    <div class="chip chip-outline chip-dark ms-05 mb-05">
                        <span class="chip-label">Oscuro</span>
                    </div>

                </div>
            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Overview</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Pages</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Components</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>My Cards</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>