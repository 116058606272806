<template>
    
        <!-- Transacciones -->
        <div class="section mt-4">
            <div class="section-heading">
                <h2 class="title">Transacciones</h2>
                <a href="/crypto/transactions" class="link">Ver todas</a>
            </div>
            <div class="card">
                <ul class="listview flush transparent no-line image-listview detailed-list mt-1 mb-1">
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-success">
                                <ion-icon name="arrow-up-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Vender</div>
                                </div>
                                <div class="text-end">
                                    <strong>$855,24</strong>
                                    <div class="text-small">
                                        Hoy 11:38 AM
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-warning">
                                <ion-icon name="arrow-down-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Etherium</strong>
                                    <div class="text-small text-secondary">Comprar</div>
                                </div>
                                <div class="text-end">
                                    <strong>$40,68</strong>
                                    <div class="text-small">
                                        8 de marzo de 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="imaged rounded w36">
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Bitcoin</strong>
                                    <div class="text-small text-secondary">Enviar</div>
                                </div>
                                <div class="text-end">
                                    <strong>$100,00</strong>
                                    <div class="text-small">
                                        4 de marzo de 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                    <!-- item -->
                    <li>
                        <a href="#" class="item">
                            <div class="icon-box bg-secondary">
                                <ion-icon name="swap-vertical-outline"></ion-icon>
                            </div>
                            <div class="in">
                                <div>
                                    <strong>Tether</strong>
                                    <div class="text-small text-secondary">Intercambiar</div>
                                </div>
                                <div class="text-end">
                                    <strong>$2.405,48</strong>
                                    <div class="text-small">
                                        24 de febrero de 2021
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <!-- * item -->
                </ul>
            </div>
        </div>
        <!-- Transacciones -->
</template>

<script>
export default {
    mounted() {
        this.loadTransactions()
    },
    methods: {
        loadTransactions() {
            console.log('transactions')
        }
    }
}
</script>