<template>
    <div class="appHeader">
        <div class="left">
            <router-link v-bind:to="{ name: 'app-home' }" class="headerButton">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </router-link>
        </div>
        <div class="pageTitle">
            Intercambio de activos digitales
        </div>
        <div class="right">
            <!-- <a href="#" class="headerButton" onclick="toastbox('toast-1', 2000)">
                <ion-icon name="refresh"></ion-icon>
            </a> -->
        </div>
    </div>
    <div id="toast-1" class="toast-box toast-top tap-to-close bg-primary">
        <div class="in">
            <div class="text">
                Exchange rates have been updated!
            </div>
        </div>
    </div>
    <div id="appCapsule">
        <!-- <FormCalcAction type="exchange" class="m-4" /> -->
        <form-swap class="m-4" />
    </div>
</template>

<script>
export default {
    name: '',
    mounted() {
    },
}
</script>