<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Notificaciones</div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">


        <div class="section mt-2 mb-2">
            <div class="section-title">Uso</div>
            <div class="card">
                <div class="card-body">
                    <h4>Uso básico</h4>
                    Activarlo con;
                    <p>
                        <code>notification('notificationID')</code>
                    </p>
                    <h4>Cierre automático</h4>
                    Además, puedes cerrarlo automáticamente en segundos después de las llamadas.
                    <br>
                    <strong>Ejemplo: </strong>
                    Cierre automático en 3 segundos.<br>
                    <code>notification('notificationID', 3000)</code>
                </div>
            </div>
        </div>


        <div class="section mt-1">
            <div class="section-title">Estilo Android</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" @click="notification('notification-1')">
                            <div class="in">
                                <div>Predeterminado</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-2')">
                            <div class="in">
                                <div>Tocar para cerrar</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-3')">
                            <div class="in">
                                <div>Con imagen</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-4')">
                            <div class="in">
                                <div>Con icono</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-5')">
                            <div class="in">
                                <div>Con botón</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-6', 3000)">
                            <div class="in">
                                <div>Cierre automático (en 3s)</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- android style 1 -->
        <div id="notification-1" class="notification-box">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                    <a href="#" class="close-button">
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * android style 1 -->

        <!-- android style 2 -->
        <div id="notification-2" class="notification-box tap-to-close">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Tocar para cerrar</h3>
                        <div class="text">
                            Toca esta notificación para cerrarla.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * android style 2 -->

        <!-- android style 3 -->
        <div id="notification-3" class="notification-box">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                    <a href="#" class="close-button">
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                    <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                </div>
            </div>
        </div>
        <!-- * android style 3 -->

        <!-- android style 4 -->
        <div id="notification-4" class="notification-box">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                    <a href="#" class="close-button">
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                    <div class="icon-box text-success">
                        <ion-icon name="cash-outline"></ion-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- * android style 4 -->

        <!-- android style 5 -->
        <div id="notification-5" class="notification-box">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                    <a href="#" class="close-button">
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                </div>
                <div class="notification-footer">
                    <a href="#" class="notification-button">
                        <ion-icon name="wallet-outline"></ion-icon>
                        Mi billetera
                    </a>
                    <a href="#" class="notification-button">
                        <ion-icon name="card-outline"></ion-icon>
                        Tarjeta de débito
                    </a>
                </div>
            </div>
        </div>
        <!-- * android style 5 -->

        <!-- android style 6 -->
        <div id="notification-6" class="notification-box">
            <div class="notification-dialog android-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                        <span>Hace 5 minutos</span>
                    </div>
                    <a href="#" class="close-button">
                        <ion-icon name="close"></ion-icon>
                    </a>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Cierre automático en 3 segundos.</h3>
                        <div class="text">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * android style 6 -->




        <div class="section mt-2">
            <div class="section-title">Estilo iOS</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" @click="notification('notification-7')">
                            <div class="in">
                                <div>Predeterminado</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-8')">
                            <div class="in">
                                <div>Tocar para cerrar</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-9')">
                            <div class="in">
                                <div>Con imagen</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-10')">
                            <div class="in">
                                <div>Con icono</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-11')">
                            <div class="in">
                                <div>Con botón</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-12', 3000)">
                            <div class="in">
                                <div>Cierre automático (en 3s)</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <!-- ios style 7-->
        <div id="notification-7" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 7 -->

        <!-- ios style 8 -->
        <div id="notification-8" class="notification-box tap-to-close">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Tocar para cerrar</h3>
                        <div class="text">
                            Toca esta notificación para cerrarla.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 8 -->

        <!-- ios style 9-->
        <div id="notification-9" class="notification-box tap-to-close">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Se ha enviado el pago</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                    <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                </div>
            </div>
        </div>
        <!-- * ios style 9-->

        <!-- ios style 10-->
        <div id="notification-10" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">El pago se ha enviado</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                    <div class="icon-box text-success">
                        <ion-icon name="finger-print-outline"></ion-icon>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 10 -->

        <!-- ios style 11 -->
        <div id="notification-11" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">El pago se ha enviado</h3>
                        <div class="text">
                            Acabas de enviar $ 200 a John.
                        </div>
                    </div>
                </div>
                <div class="notification-footer">
                    <a href="#" class="notification-button">
                        <ion-icon name="wallet-outline"></ion-icon>
                        Mi Billetera
                    </a>
                    <a href="#" class="notification-button">
                        <ion-icon name="card-outline"></ion-icon>
                        Tarjeta de débito
                    </a>
                </div>
            </div>
        </div>
        <!-- * ios style 11-->

        <!-- ios style 12 -->
        <div id="notification-12" class="notification-box">
            <div class="notification-dialog ios-style">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Cierre automático en 3 segundos.</h3>
                        <div class="text">
                            Lorem ipsum dolor sit amet.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 12 -->



        <div class="section mt-2 mb-2">
            <div class="section-title">Colores</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" @click="notification('notification-13')">
                            <div class="in">
                                <div class="text-primary">Primario</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-14')">
                            <div class="in">
                                <div class="text-secondary">Secundario</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-15')">
                            <div class="in">
                                <div class="text-success">Éxito</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-16')">
                            <div class="in">
                                <div class="text-danger">Peligro</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-17')">
                            <div class="in">
                                <div class="text-warning">Advertencia</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" @click="notification('notification-18')">
                            <div class="in">
                                <div class="text-info">Información</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>



        <!-- ios style 13 -->
        <div id="notification-13" class="notification-box">
            <div class="notification-dialog ios-style bg-primary">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color Primario</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 13 -->

        <!-- ios style 14 -->
        <div id="notification-14" class="notification-box">
            <div class="notification-dialog ios-style bg-secondary">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color Secundario</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 14 -->

        <!-- ios style 15 -->
        <div id="notification-15" class="notification-box">
            <div class="notification-dialog ios-style bg-success">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color de Éxito</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 15 -->

        <!-- ios style 16 -->
        <div id="notification-16" class="notification-box">
            <div class="notification-dialog ios-style bg-danger">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color de Peligro</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 16 -->

        <!-- ios style 17 -->
        <div id="notification-17" class="notification-box">
            <div class="notification-dialog ios-style bg-warning">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color de Advertencia</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 17 -->

        <!-- ios style 18 -->
        <div id="notification-18" class="notification-box">
            <div class="notification-dialog ios-style bg-info">
                <div class="notification-header">
                    <div class="in">
                        <img src="@/assets/img/sample/avatar/avatar3.jpg" alt="image" class="imaged w24 rounded">
                        <strong>John Pacheco</strong>
                    </div>
                    <div class="right">
                        <span>Hace 5 minutos</span>
                        <a href="#" class="close-button">
                            <ion-icon name="close-circle"></ion-icon>
                        </a>
                    </div>
                </div>
                <div class="notification-content">
                    <div class="in">
                        <h3 class="subtitle">Color de Información</h3>
                        <div class="text">
                            Texto de notificación.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * ios style 18 -->



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>

<script>
export default {
    mounted() {
        this.runScript()
    },
    methods: {
        runScript() {
            let self = this
            //-----------------------------------------------------------------------
            // Notification
            // trigger notification
            var notificationCloseButton = document.querySelectorAll(".notification-box .close-button");
            var notificationTaptoClose = document.querySelectorAll(".tap-to-close .notification-dialog");
            var notificationBox = document.querySelectorAll(".notification-box");

            // close notification
            notificationCloseButton.forEach(function (el) {
                el.addEventListener("click", function (e) {
                    e.preventDefault();
                    self.closeNotificationBox();
                })
            });

            // tap to close notification
            notificationTaptoClose.forEach(function (el) {
                el.addEventListener("click", function (e) {
                    self.closeNotificationBox();
                })
            });
            //-----------------------------------------------------------------------
        },
        closeNotificationBox() {
            let self = this
            var notificationBox = document.querySelectorAll(".notification-box");
            notificationBox.forEach(function (el) {
                el.classList.remove("show")
            })
        },
        notification(target, time) {
            let self = this
            var a = document.getElementById(target);
            self.closeNotificationBox()
            setTimeout(() => {
                a.classList.add("show")
            }, 250);
            if (time) {
                time = time + 250;
                setTimeout(() => {
                    self.closeNotificationBox()
                }, time);
            }
        }
    }
}
</script>