<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Agregar a inicio</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section full mt-2">
            <div class="section-title">Vista previa</div>
            <ul class="listview image-listview inset">
                <li>
                    <a href="#" class="item" @click="androidAddtoHome();">
                        <div class="icon-box bg-success">
                            <ion-icon name="logo-android"></ion-icon>
                        </div>
                        <div class="in">
                            <div>Android</div>
                        </div>
                    </a>
                </li>
                <li>
                    <a href="#" class="item" @click="iosAddtoHome();">
                        <div class="icon-box bg-secondary">
                            <ion-icon name="logo-apple"></ion-icon>
                        </div>
                        <div class="in">
                            <div>iOS</div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>



        <!-- Hoja de acción para agregar a la pantalla de inicio en iOS -->
        <div class="modal inset fade action-sheet ios-add-to-home" id="ios-add-to-home-screen" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar a la pantalla de inicio</h5>
                        <a href="#" class="close-button" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-center">
                            <div class="mb-1"><img src="@/assets/img/icon/192x192.png" alt="image"
                                    class="imaged w64 mb-2">
                            </div>
                            <div>
                                Instale <strong>SombreroWallet</strong> en la pantalla de inicio de su iPhone.
                            </div>
                            <div>
                                Toque <ion-icon name="share-outline"></ion-icon> y Agregar a la pantalla de inicio.
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CERRAR</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción para agregar a la pantalla de inicio en iOS -->


        <!-- Hoja de acción para agregar a la pantalla de inicio en Android -->
        <div class="modal inset fade action-sheet android-add-to-home" id="android-add-to-home-screen" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Agregar a la pantalla de inicio</h5>
                        <a href="#" class="close-button" data-bs-dismiss="modal">
                            <ion-icon name="close"></ion-icon>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content text-center">
                            <div class="mb-1">
                                <img src="@/assets/img/icon/192x192.png" alt="image" class="imaged w64 mb-2">
                            </div>
                            <div>
                                Instale <strong>SombreroWallet</strong> en la pantalla de inicio de su Android.
                            </div>
                            <div>
                                Toque <ion-icon name="ellipsis-vertical"></ion-icon> y Agregar a la pantalla de inicio.
                            </div>
                            <div class="mt-2">
                                <button class="btn btn-primary btn-block" data-bs-dismiss="modal">CERRAR</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Hoja de acción para agregar a la pantalla de inicio en Android -->

        <div class="section mt-2 mb-4">
            <div class="section-title">Uso</div>
            <div class="card">
                <div class="card-body">
                    <h4>Preparar</h4>
                    <p class="mb-3">
                        No olvide pegar los códigos html de los modales de agregar a inicio en la página html que desea utilizar.
                    </p>
                    <h4>Activar</h4>
                    <p class="mb-3">
                        Puede mostrar el componente Agregar a inicio con el código javascript <code>AddtoHome();</code>.
                        Se muestra cada vez que se carga la página.
                    </p>
                    <h4>Activar después de un retraso</h4>
                    <p class="mb-3">
                        Puede establecer un tiempo de retraso.
                        <br>
                        <strong>Ejemplo:</strong>
                        <br>
                        <code>AddtoHome("5000");</code>
                        <br>
                        Activar en 5 segundos después de que se cargue la página.
                    </p>
                    <h4>Activar solo una vez</h4>
                    <p class="mb-0">
                        Active el modal solo una vez para los usuarios.
                        <br>
                        <code>AddtoHome("5000", "once");</code>
                        <br>
                        Con este ejemplo, puede activar el componente agregar a inicio solo una vez para el usuario.
                    </p>

                </div>
            </div>
        </div>



    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->


</template>

<script>
import {bootstrap,Modal} from 'bootstrap';
export default {
    mounted() {
        this.AddtoHome("2000", "once");
    },
    methods: {
        iosAddtoHome() {
            var modal = new Modal(document.getElementById('ios-add-to-home-screen'))
            modal.toggle()
        },
        androidAddtoHome() {
            var modal = new Modal(document.getElementById('android-add-to-home-screen'))
            modal.toggle()
        },
        AddtoHome(time, once) {
            // Add to Home
            var osDetection = navigator.userAgent || navigator.vendor || window.opera;
            var windowsPhoneDetection = /windows phone/i.test(osDetection);
            var androidDetection = /android/i.test(osDetection);
            var iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !window.MSStream;
            
            if (once) {
                var AddHomeStatus = localStorage.getItem("FinappAddtoHome");
                if (AddHomeStatus === "1" || AddHomeStatus === 1) {
                    // already showed up
                }
                else {
                    localStorage.setItem("FinappAddtoHome", 0)
                    window.addEventListener('load', () => {
                        if (navigator.standalone) {
                            // if app installed ios home screen
                        }
                        else if (matchMedia('(display-mode: standalone)').matches) {
                            // if app installed android home screen
                        }
                        else {
                            // if app is not installed
                            if (androidDetection) {
                                setTimeout(() => {
                                    self.androidAddtoHome()
                                }, time);
                            }
                            if (iosDetection) {
                                setTimeout(() => {
                                    self.iosAddtoHome()
                                }, time);
                            }
                        }
                    });
                }
            }
            else {
                window.addEventListener('load', () => {
                    if (navigator.standalone) {
                        // app loaded to ios
                    }
                    else if (matchMedia('(display-mode: standalone)').matches) {
                        // app loaded to android
                    }
                    else {
                        // app not loaded
                        if (androidDetection) {
                            setTimeout(() => {
                                androidAddtoHome()
                            }, time);
                        }
                        if (iosDetection) {
                            setTimeout(() => {
                                iosAddtoHome()
                            }, time);
                        }
                    }
                });
            }
        }
    },
}
</script>