<template>
    

    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Búsqueda</div>
        <div class="right">
            <a href="#" class="headerButton toggle-searchbox">
                <ion-icon name="search-outline"></ion-icon>
            </a>
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Componente de búsqueda -->
    <div id="search" class="appHeader">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control" placeholder="Buscar...">
                <i class="input-icon icon ion-ios-search"></i>
                <a href="#" class="ms-1 close toggle-searchbox"><i class="icon ion-ios-close-circle"></i></a>
            </div>
        </form>
    </div>
    <!-- * Componente de búsqueda -->

    <!-- Encabezado adicional -->
    <div class="extraHeader">
        <form class="search-form">
            <div class="form-group searchbox">
                <input type="text" class="form-control">
                <i class="input-icon">
                    <ion-icon name="search-outline"></ion-icon>
                </i>
            </div>
        </form>
    </div>
    <!-- * Encabezado adicional -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="extra-header-active full-height">


        <div class="section mt-1 mb-2">
            <div class="section-title">Se encontraron 43 resultados para "<span class="text-primary">Depósito</span>"</div>
            <div class="card">
                <ul class="listview image-listview media transparent flush">
                    <li>
                        <a href="#" class="item">
                            <div class="imageWrapper">
                                <img src="@/assets/img/sample/photo/1.jpg" alt="image" class="imaged w64">
                            </div>
                            <div class="in">
                                <div>
                                    ¿Cuál será el valor de bitcoin en el próximo...
                                    <div class="text-muted">Lorem ipsum dolor sit amet...</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="imageWrapper">
                                <img src="@/assets/img/sample/photo/2.jpg" alt="image" class="imaged w64">
                            </div>
                            <div class="in">
                                <div>
                                    Reglas que necesitas saber en los negocios
                                    <div class="text-muted">Lorem ipsum dolor sit amet...</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="imageWrapper">
                                <img src="@/assets/img/sample/photo/3.jpg" alt="image" class="imaged w64">
                            </div>
                            <div class="in">
                                <div>
                                    10 maneras fáciles de ahorrar dinero
                                    <div class="text-muted">Lorem ipsum dolor sit amet...</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="imageWrapper">
                                <img src="@/assets/img/sample/photo/4.jpg" alt="image" class="imaged w64">
                            </div>
                            <div class="in">
                                <div>
                                    Sigue la agenda financiera con...
                                    <div class="text-muted">Lorem ipsum dolor sit amet...</div>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="imageWrapper">
                                <img src="@/assets/img/sample/photo/5.jpg" alt="image" class="imaged w64">
                            </div>
                            <div class="in">
                                <div>
                                    ¿Tiene sentido invertir en criptomonedas?
                                    <div class="text-muted">Lorem ipsum dolor sit amet...</div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="section mb-2">
            <a href="#" class="btn btn-block btn-secondary">Cargar más</a>
        </div>




    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú inferior de la aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Configuración</strong>
            </div>
        </a>
    </div>
    <!-- * Menú inferior de la aplicación -->

</template>