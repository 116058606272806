<template>
    <div class="accordion" id="accordionExample1">
        <slot />
    </div>
</template>

<script>
export default {

}
</script>