<template>
    <!-- Encabezado de la aplicación -->
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <a href="/" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule">

        <div class="section mt-2 text-center">
            <h1>Cree su codigo de seguridad</h1>
            <h4>Para iniciar, vamos a configurar un <strong>PIN de seguridad de 4 digitos</strong></h4>
            <p>Es importante que lo recuerdes y/o lo guardes en un lugar confibale, ya que siempre sera utilizado para autorizar las transacciones.</p>
        </div>
        <div class="section mb-5 p-2">
            <form @submit.prevent="submitForm">
                <div class="form-group basic">
                    <input type="password" class="form-control verification-input" placeholder="••••" maxlength="4" v-model="form.pin" required>
                </div>

                <div class="form-button-group transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Verificar</button>
                </div>

            </form>
        </div>

    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Dialog Form -->
    <div class="modal fade dialogbox" id="DialogForm" data-bs-backdrop="static" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Verificar PIN</h5>
                </div>
                <form @submit.prevent="submitFormCreate">
                    <div class="modal-body text-start mb-2">

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <div class="form-group basic">
                                    <input type="password" class="form-control verification-input" placeholder="••••" maxlength="4" v-model="form.verify">
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <div class="btn-inline">
                            <button type="button" class="btn btn-text-secondary" data-bs-dismiss="modal">CANCELAR</button>
                            <button type="submit" class="btn btn-text-primary">CONFIRMAR</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- * Dialog Form -->
</template>

<script>
import store from '@/store';
import { generateMnemonic, createWalletFromSeedWithPasswordAndPin } from '@/utils/web3';
import { Modal } from 'bootstrap';

export default {
    data() {
        return {
            form: {
                pin: '',
                verify: '',
            },
            wallet: null
        };
    },
    methods: {
        submitForm() {
            console.log('submitForm', this.form.pin)
            if (String(this.form.pin).length == 4) {
                var DialogForm = new Modal(document.getElementById('DialogForm'), {
                    keyboard: false
                })
                DialogForm.show()
            }
        },
        async submitFormCreate() {
            console.log('submitFormCreate')
            if (this.form.pin != this.form.verify) return console.log("PIN INVALIDO");
            const mnemonic = generateMnemonic()
            console.log('mnemonic', mnemonic)
            const wallet = createWalletFromSeedWithPasswordAndPin(mnemonic, "", this.form.pin)
            console.log('wallet', wallet)
            this.wallet = {
                mnemonic: mnemonic,
                address: wallet.address,
                privateKey: wallet.privateKey,
            }
            localStorage.setItem('wallet', this.wallet)
            localStorage.setItem('pkey', this.wallet.privateKey)
            await store.dispatch('loadWallet')
            this.$router.push('/');
            // location.reload()
            // this.$router.push('/auth/tx/' + tx.hash);
        }
    }
}
</script>