<template>
    

        <!-- Deslizador -->
        <div class="section mt-4 full">
            <div class="carousel-single splide">
                <div class="splide__track">
                    <ul class="splide__list">

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon bg-success mb-2">
                                        <ion-icon name="link"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Recomienda a un amigo</h3>

                                    <p>¡Invita a tus amigos y gana premios!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="#" class="btn btn-secondary">
                                                <ion-icon name="copy-outline"></ion-icon>
                                                Invitar ahora
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon mb-2">
                                        <ion-icon name="chatbox-ellipses"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Únete a nuestro chat grupal</h3>

                                    <p>¡Hablemos sobre el mercado y las estrategias!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="component-messages.html" class="btn btn-block btn-primary">
                                                Unirse ahora
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a href="component-messages.html" class="btn btn-block btn-secondary">
                                                Ver grupos
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="splide__slide">
                            <div class="card card-with-icon">
                                <div class="card-body pt-3 pb-3 text-center">
                                    <div class="card-icon bg-secondary mb-2">
                                        <ion-icon name="share-social-outline"></ion-icon>
                                    </div>
                                    <h3 class="card-titlde mb-1">Síguenos</h3>

                                    <p>¡Síguenos en las redes sociales!</p>
                                    <div class="row">
                                        <div class="col">
                                            <a href="#" class="btn btn-block btn-facebook">
                                                <ion-icon name="logo-facebook"></ion-icon>
                                                Facebook
                                            </a>
                                        </div>
                                        <div class="col">
                                            <a href="#" class="btn btn-block btn-twitter">
                                                <ion-icon name="logo-twitter"></ion-icon>
                                                Twitter
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <!-- * Deslizador -->
</template>