<template>
    
    <!-- Encabezado de la Aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Hoja de acción</div>
        <div class="right"></div>
    </div>
    <!-- * Encabezado de la Aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule" class="full-height">


        <div class="section mt-2">
            <div class="section-title">Básico</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheet">
                            <div class="in">
                                <div>Predeterminado</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetInset">
                            <div class="in">
                                <div>Insertado</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetIconed">
                            <div class="in">
                                <div>Con icono</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>


        <div class="section mt-2 mb-3">
            <div class="section-title">Más</div>
            <div class="card">
                <ul class="listview flush transparent image-listview text">
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetForm">
                            <div class="in">
                                <div>Con formulario</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetContent">
                            <div class="in">
                                <div>Contenido</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetAlertIconed">
                            <div class="in">
                                <div>Alerta - Con icono</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetAlertSuccess">
                            <div class="in">
                                <div>Alerta - Éxito</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetAlertError">
                            <div class="in">
                                <div>Alerta - Error</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="item" data-bs-toggle="modal" data-bs-target="#actionSheetAlertImaged">
                            <div class="in">
                                <div>Alerta - Con imagen</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Default Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheet" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título de la hoja de acción</h5>
                    </div>
                    <div class="modal-body">
                        <ul class="action-button-list">
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Enviar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Retirar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Intercambiar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Depósito</span>
                                </a>
                            </li>
                            <li class="action-divider"></li>
                            <li>
                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                    <span>Cancelar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Default Action Sheet -->

        <!-- Default Action Sheet Inset -->
        <div class="modal fade action-sheet inset" id="actionSheetInset" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título de la hoja de acción</h5>
                    </div>
                    <div class="modal-body">
                        <ul class="action-button-list">
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Enviar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Retirar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Intercambiar</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>Depósito</span>
                                </a>
                            </li>
                            <li class="action-divider"></li>
                            <li>
                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                    <span>Cancelar</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Default Action Sheet Inset -->

        <!-- Iconed Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetIconed" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Título de la hoja de acción</h5>
                    </div>
                    <div class="modal-body">
                        <ul class="action-button-list">
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="arrow-forward-outline"></ion-icon>
                                        Enviar
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="card-outline"></ion-icon>
                                        Retirar
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="cash-outline"></ion-icon>
                                        Intercambiar
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="btn btn-list" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="wallet-outline"></ion-icon>
                                        Depósito
                                    </span>
                                </a>
                            </li>
                            <li class="action-divider"></li>
                            <li>
                                <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                    <span>
                                        <ion-icon name="close-outline"></ion-icon>
                                        Cancelar
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Iconed Action Sheet -->

        <!-- Form Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetForm" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Depositar dinero</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">

                            <form>
                                <div class="form-group basic">
                                    <div class="input-wrapper">
                                        <label class="label" for="account1">De</label>
                                        <select class="form-control custom-select" id="account1">
                                            <option value="0">Ahorros (*** 5019)</option>
                                            <option value="1">Inversión (*** 6212)</option>
                                            <option value="2">Hipoteca (*** 5021)</option>
                                        </select>
                                    </div>
                                    <div class="input-info">Seleccione una cuenta bancaria</div>
                                </div>

                                <div class="form-group basic">
                                    <label class="label">Ingresar cantidad</label>
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1">$</span>
                                        <input type="text" class="form-control" placeholder="Ingresar una cantidad"
                                            value="100">
                                    </div>
                                    <div class="input-info">Mínimo $50</div>
                                </div>

                                <div class="form-group basic">
                                    <button type="button" class="btn btn-primary btn-block btn-lg"
                                        data-bs-dismiss="modal">Depositar</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Form Action Sheet -->

        <!-- Content Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetContent" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Contenido de la hoja de acción</h5>
                    </div>
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum, urna eget
                                finibus fermentum, velit metus maximus erat, nec sodales elit justo vitae sapien. Sed
                                fermentum varius erat, et dictum lorem. Cras pulvinar vestibulum purus sed hendrerit.
                                Praesent et auctor dolor. Ut sed ultrices justo. Fusce tortor erat, scelerisque sit amet
                                diam rhoncus, cursus dictum lorem. Ut vitae arcu egestas, congue nulla at, gravida
                                purus.
                            </p>
                            <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Cerrar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Content Action Sheet -->

        <!-- Alert Iconed Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetAlertIconed" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div class="iconbox text-primary">
                                <ion-icon name="finger-print"></ion-icon>
                            </div>
                            <div class="text-center p-2">
                                <h3>Se requiere Touch ID</h3>
                                <p>Su pago ha sido enviado a John.</p>
                            </div>
                            <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Hecho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Alert Iconed Action Sheet -->

        <!-- Alert Success Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetAlertSuccess" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div class="iconbox text-success">
                                <ion-icon name="checkmark-circle"></ion-icon>
                            </div>
                            <div class="text-center p-2">
                                <h3>Éxito</h3>
                                <p>Su pago ha sido enviado a John.</p>
                            </div>
                            <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Hecho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Alert Success Action Sheet -->

        <!-- Alert Error Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetAlertError" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div class="iconbox text-danger">
                                <ion-icon name="alert-circle"></ion-icon>
                            </div>
                            <div class="text-center p-2">
                                <h3>Error</h3>
                                <p>Algo salió mal.</p>
                            </div>
                            <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Hecho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Alert Error Action Sheet -->

        <!-- Alert Imaged Action Sheet -->
        <div class="modal fade action-sheet" id="actionSheetAlertImaged" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="action-sheet-content">
                            <div class="iconbox">
                                <img src="@/assets/img/sample/avatar/avatar4.jpg" alt="image" class="imaged w64 rounded">
                            </div>
                            <div class="text-center p-2">
                                <h3>Jane te envió $200</h3>
                                <p>Este es un texto de ejemplo.</p>
                            </div>
                            <a href="#" class="btn btn-primary btn-lg btn-block" data-bs-dismiss="modal">Hecho</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- * Alert Imaged Action Sheet -->

    </div>
    <!-- * App Capsule -->

    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Visión general</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * App Bottom Menu -->

</template>