<template>
    <!-- Encabezado de la aplicación -->
    <div class="appHeader no-border transparent position-absolute">
        <div class="left">
            <a href="/auth" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la Aplicación -->
    <div id="appCapsule" class="extra-header-active full-height">
        <div class="section mt-2 mb-1">
            <div class="row">
                <div class="col-6 mb-2">
                    <div class="bill-box">
                        <div class="img-wrapper">
                            <img src="@/assets/img/4797670.webp" alt="img" class="image-block imaged w48">
                        </div>
                        <div class="price">Frase secreta y PIN</div>
                        <p>Frase mnemotécnica</p>
                        <router-link to="/auth/login/mnemonic" class="btn btn-primary btn-block btn-sm">IMPORTAR AHORA</router-link>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="bill-box">
                        <div class="img-wrapper">
                            <img src="@/assets/img/whatis_encryption.png" alt="img" class="image-block imaged w48">
                        </div>
                        <div class="price">Clave Privada</div>
                        <p>Clave Privada</p>
                        <router-link to="/auth/login/privatekey" class="btn btn-primary btn-block btn-sm">IMPORTAR AHORA</router-link>
                    </div>
                </div>
                <div class="col-6 mb-2">
                    <div class="bill-box">
                        <div class="img-wrapper">
                            <img src="@/assets/img/sample/brand/3.jpg" alt="img" class="image-block imaged w48">
                        </div>
                        <div class="price">Sombrero MChain (Muy Pronto)</div>
                        <p>Cuenta Sombrero Multi Redes</p>
                        <a href="#" class="btn btn-primary btn-block btn-sm">Ingresar</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * Cápsula de la Aplicación -->
</template>

<script>
export default {
    
}
</script>