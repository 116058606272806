<template>
    
    <!-- Encabezado de la aplicación -->
    <div class="appHeader">
        <div class="left">
            <a href="#" class="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
            </a>
        </div>
        <div class="pageTitle">Desplegables</div>
        <div class="right dropdown">
            <a href="#" class="headerButton" data-bs-toggle="dropdown">
                <i class="icon ion-ios-more"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item" href="#">Depósito</a>
                <a class="dropdown-item" href="#">Retirada</a>
                <a class="dropdown-item" href="#">Enviar</a>
                <a class="dropdown-item" href="#">Intercambio</a>
            </div>
        </div>
    </div>
    <!-- * Encabezado de la aplicación -->

    <!-- Cápsula de la aplicación -->
    <div id="appCapsule" class="full-height">


        <div class="section mt-2">
            <div class="section-title">Básico</div>
            <div class="card">
                <div class="card-body">

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Desplegable Básico
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">Enviar</a>
                            <a class="dropdown-item" href="#">Depósito</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Cancelar</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="section mt-2">
            <div class="section-title">Alineación Derecha</div>
            <div class="card">
                <div class="card-body">

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Desplegable Derecha
                        </button>
                        <!-- agregar .dropdown-menu-end -->
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#">Enviar</a>
                            <a class="dropdown-item" href="#">Depósito</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Cancelar</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Con Icono</div>
            <div class="card">
                <div class="card-body">

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Desplegable con Icono
                        </button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">
                                <ion-icon name="arrow-redo-outline"></ion-icon>
                                Enviar
                            </a>
                            <a class="dropdown-item" href="#">
                                <ion-icon name="card-outline"></ion-icon>
                                Depósito
                            </a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">
                                <ion-icon name="close-outline"></ion-icon>
                                Cancelar
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <div class="section mt-2">
            <div class="section-title">Con Encabezado</div>
            <div class="card">
                <div class="card-body">

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Desplegable con Encabezado
                        </button>
                        <div class="dropdown-menu">
                            <h6 class="dropdown-header">Encabezado del desplegable</h6>
                            <a class="dropdown-item" href="#">Enviar</a>
                            <a class="dropdown-item" href="#">Depósito</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Cancelar</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="section mt-2 mb-2">
            <div class="section-title">Texto Adicional</div>
            <div class="card">
                <div class="card-body">

                    <div class="dropdown">
                        <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Desplegable con Texto
                        </button>
                        <div class="dropdown-menu">
                            <div class="text">
                                Algún texto de ejemplo que fluye libremente dentro del menú desplegable.
                            </div>
                            <a class="dropdown-item" href="#">Copiar</a>
                            <a class="dropdown-item" href="#">Guardar</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#">Eliminar</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>




    </div>
    <!-- * Cápsula de la aplicación -->

    <!-- Menú Inferior de la Aplicación -->
    <div class="appBottomMenu">
        <a href="index.html" class="item">
            <div class="col">
                <ion-icon name="pie-chart-outline"></ion-icon>
                <strong>Resumen</strong>
            </div>
        </a>
        <a href="app-pages.html" class="item">
            <div class="col">
                <ion-icon name="document-text-outline"></ion-icon>
                <strong>Páginas</strong>
            </div>
        </a>
        <a href="app-components.html" class="item">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>Componentes</strong>
            </div>
        </a>
        <a href="app-cards.html" class="item">
            <div class="col">
                <ion-icon name="card-outline"></ion-icon>
                <strong>Mis Tarjetas</strong>
            </div>
        </a>
        <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Ajustes</strong>
            </div>
        </a>
    </div>
    <!-- * Menú Inferior de la Aplicación -->

</template>